import React, { useState } from "react";
import { toast } from "react-toastify";

import {
  handleGeneratePDFtrois,
  generateValidationMessage,
  generateValidationInfoMessage,
} from "../extras/auxiliary_functions";

const ldtInfoValidations = (values: any, onValidationInfoMessages: any) => {
  if (values.program !== "ldt") return;
  const validationMessages = generateValidationInfoMessage(values);
  if (validationMessages.length === 0) return;
  const alertDiv = document.getElementById("alertDivInfoValidations");
  if (alertDiv?.classList.contains("hidden")) {
    alertDiv.classList.replace("hidden", "flex");
  }
  onValidationInfoMessages(validationMessages);
};

export const SubmitPDF: React.FC<{
  values: any;
  formRef: React.RefObject<any>;
  openModalPdf: any;
  setUrlPdfFileStorage: any;
  setPdfFileName: any;
  onValidationErrorMessages: (messagesError: string[]) => void;
  onValidationInfoMessages: (messagesInfo: string[]) => void;
  smartsheetOverridesProgramLastQuarterRelevantIndividual: any;
}> = ({
  values,
  formRef,
  openModalPdf,
  setUrlPdfFileStorage,
  setPdfFileName,
  onValidationErrorMessages,
  onValidationInfoMessages,
  smartsheetOverridesProgramLastQuarterRelevantIndividual,
}) => {
  const [isSubmittingPDF, setIsSubmittingPDF] = useState(false);

  const handleSubmitPDF = async () => {
    setIsSubmittingPDF(true);
    try {
      // Submit the form and check for errors
      const submitFormResponse = await formRef.current.submitForm();
      const errors = formRef.current.errors;

      ldtInfoValidations(values, onValidationInfoMessages);

      if (Object.keys(errors).length > 0) {
        // Early return if errors are found
        return;
      }

      const { id_form_submission } = submitFormResponse;
      values["id_form_submission"] = id_form_submission;

      // Validation message generation
      const isValidationEnabled = true; // Toggle for validation logic
      const validationMessages = isValidationEnabled
        ? generateValidationMessage(
            values,
            smartsheetOverridesProgramLastQuarterRelevantIndividual,
          )
        : [];

      // Handle validation messages
      if (validationMessages.length > 0) {
        const alertDiv = document.getElementById("alertDivErrorValidations");
        alertDiv?.classList.replace("hidden", "flex"); // Simplified class manipulation
        onValidationErrorMessages(validationMessages); // Pass messages to parent
      } else {
        // Proceed to PDF generation if no validation errors
        await handleGeneratePDFtrois(
          values,
          setUrlPdfFileStorage,
          setPdfFileName,
        );
        openModalPdf();
      }
    } catch (error) {
      console.error("Error submitting PDF:", error);
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsSubmittingPDF(false);
    }
  };

  return (
    <>
      <button
        id="submitPdfButton"
        className={`button my-16 ml-6 ${
          isSubmittingPDF ? "disabled-button" : ""
        }`}
        type="button"
        disabled={isSubmittingPDF}
        onClick={() => handleSubmitPDF()}
      >
        Preview & Submit
      </button>
    </>
  );
};

export const SubmitBasic: React.FC<{
  values: any;
  isSubmitting: any;
  formRef: React.RefObject<any>;
  onValidationInfoMessages: (messagesInfo: string[]) => void;
}> = ({ values, isSubmitting, formRef, onValidationInfoMessages }) => {
  return (
    <button
      className={`button my-16 ${isSubmitting ? "disabled-button" : ""}`}
      type="submit"
      disabled={isSubmitting}
      onClick={() => {
        if (!isSubmitting) {
          formRef?.current?.submitForm();
          ldtInfoValidations(values, onValidationInfoMessages);
        }
      }}
    >
      Save Draft
    </button>
  );
};
