import { Link } from "react-router";

const HeaderStanford = () => {
  return (
    <>
      <div className="bg-digital-red pt-5 pb-1">
        <div className="cc">
          <a
            className="logo text-white hocus:text-white text-20 mt-3 mb-3"
            href="https://ed.stanford.edu"
          >
            <img
              src="/stanford_gse_logo_ffffff.svg"
              alt="Stanford GSE Logo"
              className="inline-block mr-2 w-1/3"
            />
          </a>
        </div>
      </div>
      <div
        role="banner"
        className="rs-pt-8 rs-pb-1 bg-cover bg-[url('/20200713_Stanford_empty_campus-81.jpeg')]"
      >
        <div className="centered-container text-white">
          <h1 className="splash-text mb-0">
            <Link
              to="/"
              className="text-white no-underline focus:text-white hover:text-white"
            >
              <div>GSE Program Proposal</div>
            </Link>
          </h1>
          {/*<p className="intro-text">Lorem Ipsum Dolor Secum</p> */}
        </div>
      </div>
    </>
  );
};

export default HeaderStanford;
