import * as Yup from "yup";

import {
  programDifferentiatorHasPlanAPlanB,
  programDifferentiatorHasProgramRequirement,
} from "./auxiliary_functions";

export const validationSchema = Yup.object().shape({
  name: Yup.string(), // you can add .required('Name is required') if it's mandatory
  //phone: Yup.string(), // you can add phone number validations if needed
  phone: Yup.string().required("Phone Required"),
  student_id: Yup.string(),
  student_email: Yup.string().email("Invalid email format"),
  //advisor_email: Yup.string().email('Invalid email format'),
  director_email: Yup.string().email("Invalid email format"),
  rows: Yup.array().of(
    Yup.object().shape({
      quarter: Yup.string(),
      year: Yup.number().required("Year is required"),
      courses: Yup.array().of(
        Yup.object().shape({
          courseNumber: Yup.string().required("Course number is required"),
          courseTitle: Yup.string().required("Course title is required"),
          gradingOption: Yup.string().required("Grading Option is required"),
          courseUnits: Yup.number().required("Units are required"),
          programRequirement: Yup.string().required(
            "Program Requirement is required",
          ),
        }),
      ),
    }),
  ),
});

export const validationSchemaSetup = (program: string) => {
  let standardRowValidations: Record<string, any> = {
    courseNumber: Yup.string().required("Course number is required"),
    courseTitle: Yup.string().required("Course title is required"),
    gradingOption: Yup.string().required("Grading Option is required"),
    courseUnits: Yup.number().required("Units are required"),
  };

  if (programDifferentiatorHasProgramRequirement(program)) {
    standardRowValidations["programRequirement"] = Yup.string().required(
      "Program Requirement is required",
    );
  }

  // @ts-ignore
  const coursesValidation0 = Yup.array()
    .of(Yup.object().shape(standardRowValidations))
    .test("validate-courses", "", function (value) {
      // Check if any courseNumber is filled
      console.log("value in coursesValidation");
      const hasFilledCourseNumber = value?.some(
        (course) => course.courseNumber,
      );
      console.log(value);
      console.log("hasFilledCourseNumber");
      console.log(hasFilledCourseNumber);
      console.log();

      // If no courseNumber is filled, skip the validation
      if (!hasFilledCourseNumber) {
        // chatgpt when this happens I don't want courseValidation to run
        console.log("in the boolean negative");
        return true;
      } else {
        // chatgpt when this happens I do want courseValidation to run
        // If at least one courseNumber is filled, validate the courses
        try {
          Yup.array()
            .of(Yup.object().shape(standardRowValidations))
            .validateSync(value);
          return true;
        } catch (error) {
          return this.createError({
            message: "Validation failed for one or more courses.",
          });
        }
      }
    });
  // @ts-ignore
  const coursesValidation2 = Yup.array()
    .of(Yup.object().shape(standardRowValidations))
    .test("validate-courses", "", function (value) {
      const hasFilledCourseNumber = value?.some(
        (course) => course.courseNumber,
      );

      // If no courseNumber is filled, skip the validation
      if (!hasFilledCourseNumber) {
        return true; // Skip validation
      }

      // If at least one courseNumber is filled, validate the courses
      return Yup.array()
        .of(Yup.object().shape(standardRowValidations))
        .validate(value)
        .then(() => true)
        .catch(() => false);
    });

  const courseSchema = Yup.object().shape(standardRowValidations);

  // Test for each row's courses
  const coursesValidation = Yup.array()
    .of(courseSchema)
    .test(
      "validate-courses",
      "Validation failed for one or more courses.",
      function (courses) {
        const hasFilledCourseNumber = courses?.some(
          (course) => course.courseNumber, // !== "", // Check if courseNumber is filled
        );
        //console.log(courses);

        if (!hasFilledCourseNumber) {
          //console.log("failed out");

          // Skip validation if no courseNumber is filled
          return true;
        } else {
          //console.log("good");
          // Validate courses if at least one courseNumber is filled
          return Yup.array()
            .of(courseSchema)
            .validate(courses)
            .then(() => true)
            .catch(() => false);
        }
      },
    );

  let rowsValidation = Yup.array().of(
    Yup.object().shape({
      quarter: Yup.string(),
      year: Yup.string().required("Year is required"),
      courses: coursesValidation,
    }),
  );

  // hcp, students "The default is A/W/Sp year 1 + A/W/Sp/Su year 2." they may have an empty summer 1
  // allow an empty quarter with no content
  // eds may also have an empty summer quarter
  if (
    program === "joint_ma_mba" ||
    program === "designed" ||
    program === "hcp"
    //program === "eds"
  ) {
    rowsValidation = Yup.array().of(
      Yup.object().shape({
        quarter: Yup.string(),
        year: Yup.string().required("Year is required"),
      }),
    );
  }

  let standardIntakeValidations: Record<string, any> = {
    name: Yup.string(),
    //phone: Yup.string().required("Phone Required"),
    student_id: Yup.string(),
    student_email: Yup.string().email("Invalid email format"),
    director_email: Yup.string().email("Invalid email format"),
    rows: rowsValidation,
  };

  if (programDifferentiatorHasPlanAPlanB(program)) {
    standardIntakeValidations["plan"] = Yup.string().required(
      "Plan selection is required",
    );
  }

  return Yup.object().shape(standardIntakeValidations);
};

export const validationSchemaSetupBetter = (program: string) => {
  let standardRowValidations: Record<string, any> = {
    courseNumber: Yup.string().required("Course number is required"),
    courseTitle: Yup.string().required("Course title is required"),
    gradingOption: Yup.string().required("Grading Option is required"),
    courseUnits: Yup.number().required("Units are required"),
  };

  if (programDifferentiatorHasProgramRequirement(program)) {
    standardRowValidations["programRequirement"] = Yup.string().required(
      "Program Requirement is required",
    );
  }

  let rowsValidation = Yup.array().of(
    Yup.object().shape({
      quarter: Yup.string(),
      year: Yup.string().required("Year is required"),
      courses: Yup.array()
        .of(Yup.object().shape(standardRowValidations))
        .test(
          "validate-courses",
          "At least one course must have a filled course number",
          function (value) {
            // Check if any courseNumber is filled
            return value
              ? value.some((course) => course.courseNumber.trim() === "")
              : true;
          },
        ),
    }),
  );

  if (program === "joint_ma_mba" || program === "designed") {
    rowsValidation = Yup.array().of(
      Yup.object().shape({
        quarter: Yup.string(),
        year: Yup.string().required("Year is required"),
      }),
    );
  }

  let standardIntakeValidations: Record<string, any> = {
    name: Yup.string(),
    phone: Yup.string().required("Phone Required"),
    student_id: Yup.string(),
    student_email: Yup.string().email("Invalid email format"),
    director_email: Yup.string().email("Invalid email format"),
    rows: rowsValidation,
  };

  if (programDifferentiatorHasPlanAPlanB(program)) {
    standardIntakeValidations["plan"] = Yup.string().required(
      "Plan selection is required",
    );
  }

  return Yup.object().shape(standardIntakeValidations);
};

// original one
export const validationSchemaSetup0 = (program: string) => {
  let standardRowValidations: Record<string, any> = {
    courseNumber: Yup.string().required("Course number is required"),
    courseTitle: Yup.string().required("Course title is required"),
    gradingOption: Yup.string().required("Grading Option is required"),
    courseUnits: Yup.number().required("Units are required"),
  };
  if (programDifferentiatorHasProgramRequirement(program)) {
    standardRowValidations["programRequirement"] = Yup.string().required(
      "Program Requirement is required",
    );
  }
  let rowsValidation;
  if (program === "joint_ma_mba" || program === "designed") {
    rowsValidation = Yup.array().of(
      Yup.object().shape({
        quarter: Yup.string(),
        year: Yup.string().required("Year is required"),
      }),
    );
  } else {
    rowsValidation = Yup.array().of(
      Yup.object().shape({
        quarter: Yup.string(),
        year: Yup.string().required("Year is required"),
        courses: Yup.array().of(Yup.object().shape(standardRowValidations)),
      }),
    );
  }

  let standardIntakeValidations: Record<string, any> = {
    name: Yup.string(),
    phone: Yup.string().required("Phone Required"),
    student_id: Yup.string(),
    student_email: Yup.string().email("Invalid email format"),
    director_email: Yup.string().email("Invalid email format"),
    rows: rowsValidation,
  };

  if (programDifferentiatorHasPlanAPlanB(program)) {
    standardIntakeValidations["plan"] = Yup.string().required(
      "Plan selection is required",
    );
  }

  return Yup.object().shape(standardIntakeValidations);

  //const customCheckboxValidation: any = (value: any) => {
  //  // At least one checkbox should be checked
  //  console.log("value");
  //  console.log(value);
  //  const isChecked = Object.values(value).some(
  //    (checkbox) => checkbox === true,
  //  );
  //  if (!isChecked) {
  //    return Yup.string().required("At least one checkbox must be checked");
  //  }
  //  return Yup.string();
  //};

  //const numberOfCheckboxes = 9;

  //const checkboxesSchema = {};
  //for (let i = 1; i <= numberOfCheckboxes; i++) {
  //  checkboxesSchema[`checkbox${i}`] = customCheckboxValidation;
  //}

  // return Yup.object().shape({
  // | name: Yup.string(), // you can add .required('Name is required') if it's mandatory
  //   //phone: Yup.string(), // you can add phone number validations if needed
  //   phone: Yup.string().required("Phone Required"),
  //   student_id: Yup.string(),
  //   student_email: Yup.string().email("Invalid email format"),
  //   //advisor_email: Yup.string().email('Invalid email format'),
  //   director_email: Yup.string().email("Invalid email format"),
  //   rows: Yup.array().of(
  //     Yup.object().shape({
  //       quarter: Yup.string(),
  //       year: Yup.number().required("Year is required"),
  //       courses: Yup.array().of(Yup.object().shape(standardRowValidations)),
  //     }),
  //   ),
  //   //checkboxes: Yup.object().shape(checkboxesSchema),
  //   //checkboxes: Yup.object().shape({
  //   //  checkbox1: customCheckboxValidation,
  //   //  checkbox2: customCheckboxValidation,
  //   //  checkbox3: customCheckboxValidation,
  //   //  checkbox4: customCheckboxValidation,
  //   //  checkbox5: customCheckboxValidation,
  //   //  checkbox6: customCheckboxValidation,
  //   //  checkbox7: customCheckboxValidation,
  //   //  checkbox8: customCheckboxValidation,
  //   //  checkbox9: customCheckboxValidation,
  //   //}),
  // });
};
