const BeyondCourseCatalogNotice = () => {
  return (
    <>
      <div className="italic text-gray-500 mt-30 mb-10">
        The courses offered below have not officially been listed in
        ExploreCourses. The units and quarters offered are subject to change --
        i.e. in future rounds filling out coursework here, the information you
        list below may need to be adjusted
      </div>
    </>
  );
};

export default BeyondCourseCatalogNotice;
