const SiteGridExample = () => {
  return (
    <>
      <div className="py-0 cc flex flex-col md:flex-row rs-pt-6">
        <div className="mx-auto w-full py-0 my-0">
          <article id="flex-grid" className="">
            <div className="flex flex-col md:flex-row gap-xs lg:gap-lg xl:gap-xl 2xl:gap-2xl text-white rs-mb-2">
              <div className="w-full md:w-1/2 bg-black-70 rs-p-2">
                <h4>Purpose</h4>
                <p className="card-paragraph">
                  This search tool is a resource for you to discover the types
                  of courses that exist at the Graduate School of Education
                  (GSE), along with how those courses intersect with program and
                  requirement affiliations.
                </p>
              </div>
              <div className="w-full md:w-1/2 bg-black-70 rs-p-2">
                <h4>Available Courses</h4>
                <p className="card-paragraph">
                  These are only courses offered by the GSE, as denoted by the
                  EDUC subject code. Courses are listed alpha-numerically, with
                  the exception of independent study courses, which are placed
                  last.
                </p>
              </div>
              <div className="w-full md:w-1/2 bg-black-70 rs-p-2">
                <h4>Program Requirements?</h4>
                <p className="card-paragraph">
                  For PhD program requirements, go&nbsp;
                  <a
                    className="text-white"
                    target="_blank"
                    href="https://ed.stanford.edu/academics/doctoral-handbook"
                  >
                    here
                  </a>
                  . <br />
                  For MA program requirements, go&nbsp;
                  <a
                    className="text-white"
                    target="_blank"
                    href="https://ed.stanford.edu/academics/masters-handbook"
                  >
                    here
                  </a>
                  . <br />
                  For UG program requirements, go&nbsp;
                  <a
                    className="text-white"
                    target="_blank"
                    href="https://ed.stanford.edu/undergraduate"
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default SiteGridExample;
