const emailData: any = {
  aaismail: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC281",
          courseTitle: "Technology for Learners",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning Theories",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "URBANST131",
          courseTitle:
            "Very Impactful People (V.I.P.):  Social Innovation & the Impact Entrepreneur",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Design Process",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 3,
          unitsMin: 2,
          courseNumber: "EDUC229A",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 7,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 4,
          courseNumber: "ENGR245",
          courseTitle:
            "The Lean LaunchPad: Getting Your Lean Startup Off the Ground",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Design Process",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC229B",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 7,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 2,
          unitsMin: 2,
          courseNumber: "EDUC295",
          courseTitle:
            "Entrepreneurship and Innovation in Education Technology Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC254",
          courseTitle: "Digital Learning Design Workshop",
          courseUnits: 2,
          gradingOption: "S/NC",
          programRequirement: "Design Process",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC171",
          courseTitle: "Preschool Counts: Engaging Young Children in Math",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Design of Learning Experiences",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 5,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "EDUC295",
          courseTitle: "Education Internship Workshop",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Internship",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 3,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC200A",
          courseTitle: "Introduction to Data Analysis and Interpretation",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Research Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "EDUC211A",
          courseTitle: "Maker Tools Learning Lab",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC421",
          courseTitle:
            "Powerful Ideas for Learning Sciences and Technology Design: Sociocultural Practices of the Blues",
          courseUnits: 3,
          gradingOption: "S/NC",
          programRequirement: "Learning Theories",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 7,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "ME206A",
          courseTitle: "Design for Extreme Affordability",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Design of Learning Experiences",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 4,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 4,
          courseNumber: "EDUC314",
          courseTitle:
            "Funkentelechy: Technologies, Social Justice and Black Vernacular Cultures (AFRICAAM 200N, CSRE 314, STS 200N)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Perspectives on Technology",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC229C",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 7,
    },
    {
      year: "2024-2025",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "EDUC215",
          courseTitle: "Education Internship Workshop",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Internship",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          courseNumber: "EDUC229D",
          courseTitle: "SEM (LRNG DESIGN TECH)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 5,
    },
  ],
  absparks: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC333A",
          courseTitle:
            "Introduction to Learning Sciences: Understanding Learning and Learning Environments",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC200A",
          courseTitle: "Introduction to Data Analysis and Interpretation",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423A",
          courseTitle:
            "Introduction to Education Data Science: Data Processing (SOC 302A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423B",
          courseTitle:
            "Introduction to Education Data Science: Data Analysis (SOC 302B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS124",
          courseTitle:
            "From Languages to Information (LINGUIST 180, LINGUIST 280)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "MS&E284",
          courseTitle:
            "Managing Data Science Organizations for Innovation and Impact",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224N",
          courseTitle:
            "Natural Language Processing with Deep Learning (LINGUIST 284, SYMSYS 195N)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC326",
          courseTitle: "Advanced Regression Analysis (SOC 384)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "DESIGN255",
          courseTitle: "The Design of Data",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "EDUC215",
          courseTitle: "Internship",
          courseUnits: 1,
          gradingOption: "Credit/No Credit",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        //{ requirement: "", num: "", title: "", letter: "" },
        //{ requirement: "", num: "", title: "", letter: "" },
        //{ requirement: "", num: "", title: "", letter: "" },
      ],
      total: 1,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 2,
          courseNumber: "EDUC473",
          courseTitle: "Empowering Educators via Language Technology (CS 293)",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS448B",
          courseTitle: "Data Visualization (EDUC 458, SYMSYS 195V)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224W",
          courseTitle: "Machine Learning with Graphs",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 9,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC315A",
          courseTitle:
            "Introduction to CSCL: Computer-Supported Collaborative Learning (CS 498C)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC430B",
          courseTitle:
            "Quasi-Experimental Research Design & Analysis (SOC 258B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC252",
          courseTitle: "Introduction to Psychometrics",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS278",
          courseTitle: "Social Computing (SOC 174, SOC 274)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 1,
          courseNumber: "EDUC407",
          courseTitle: "Lytics Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 8,
    },
  ],
  amock: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        { requirement: "Core", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
      ],
      total: 0,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
      ],
      total: 0,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
      ],
      total: 0,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        { requirement: "", num: "", title: "", letter: "" },
        { requirement: "", num: "", title: "", letter: "" },
        { requirement: "", num: "", title: "", letter: "" },
        { requirement: "", num: "", title: "", letter: "" },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
        { requirement: "", num: "", title: "", letter: "", units: 0 },
      ],
      total: 0,
    },
  ],
  antonio0: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC209A",
          courseTitle: "Policy, Organization, and Leadership Studies Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC445",
          courseTitle: "Transforming Education through Entrepreneurship",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Organization",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC421",
          courseTitle:
            "Powerful Ideas for Learning Sciences and Technology Design: Sociocultural Practices of the Blues",
          courseUnits: 3,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "PUBLPOL314",
          courseTitle: "Justice in Public Policy",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Policy",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 5,
          courseNumber: "PUBLPOL156",
          courseTitle: "Health Care Policy and Reform (HUMBIO 122A)",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "Policy",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        //{ requirement: "", num: "", title: "", letter: "", units: 0 },
      ],
      total: 18,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC209B",
          courseTitle: "Policy, Organization, and Leadership Studies Seminar",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "EDUC200B",
          courseTitle: "Introduction to Qualitative Research Methods",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC429",
          courseTitle:
            "Reducing Health Disparities and Closing the Achievement Gap through Health Integration in Schools (HUMBIO 122E, PEDS 229)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Policy",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 4,
          courseNumber: "PUBLPOL303D",
          courseTitle: "Applied Econometrics for Public Policy",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "PUBLPOL311",
          courseTitle: "Public Policy Colloquium",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 18,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC209C",
          courseTitle: "Policy, Organization, and Leadership Studies Seminar",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC275",
          courseTitle: "Leading Change in Schools",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Organization",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 4,
          courseNumber: "PUBLPOL301B",
          courseTitle:
            "Economic Policy Analysis for Policymakers (INTLPOL 204B)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC471",
          courseTitle: "The Development of Purpose Across the Lifespan",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "MED245",
          courseTitle: "Of Decisions and Dilemmas: The Art of Leadership",
          courseUnits: 2,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 18,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
  ],
  changlh: [
    //{
    //  transferred: True,
    //  year: "2022-2023",
    //  quarter: "Summer",
    //  courses: [
    //    {
    //      courseNumber: "STATS202",
    //      courseTitle: "Data Mining And Analysis",
    //      courseUnits: 3,
    //      gradingOption: "Letter",
    //      programRequirement: "Elective",
    //      availableGradingOptions: ["Letter", "Credit/No Credit"],
    //    },
    //  ],
    //  total: 3,
    //},
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 2,
          courseNumber: "EDUC229A",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "EDUC200B",
          courseTitle: "Introduction to Qualitative Research Methods",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Research Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC333A",
          courseTitle:
            "Introduction to Learning Sciences: Understanding Learning and Learning Environments",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning Theories",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC281",
          courseTitle: "Technology for Learners",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Perspectives on Technology",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC364",
          courseTitle: "Cognition and Learning",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "DESIGN240",
          courseTitle: "Creative Gym: A Design Thinking Skills Studio",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Design Process",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 15,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC237",
          courseTitle: "Learning, Making, Crafting, & Creating",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning Content",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC315A",
          courseTitle:
            "Introduction to CSCL: Computer-Supported Collaborative Learning (CS 498C)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Design of Learning Experiences",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC254",
          courseTitle: "Digital Learning Design Workshop",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Design Process",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 7,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 1,
          unitsMin: 1,
          additionalInfo: "TBD",
          courseNumber: "DESIGN261",
          courseTitle:
            "Systems Design for Health: Reimagining Stanford Campus Town Center (SUSTAIN 128)",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Design Process",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 5,
          unitsMin: 1,
          courseNumber: "EDU215A",
          courseTitle:
            "Stanford Laptop Orchestra: Composition, Coding, and Performance (MUSIC 128)",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "DLCL203",
          courseTitle: "Data Visualization With Textiles",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 3,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS229",
          courseTitle: "Machine Learning (STATS 229)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 3,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC432",
          courseTitle: "Designing Explorable Explanations for Learning",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Design of Learning Experiences",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC229B",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 5,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC229C",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship at Organization: ",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Internship",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 3,
    },
    {
      year: "2024-2025",
      quarter: "Summer",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 2,
          courseNumber: "EDUC229D",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship at Organization: ",
          courseUnits: 1,
          gradingOption: "Credit/No Credit",
          programRequirement: "Internship",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 3,
    },
  ],
  eparish: [
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC347",
          courseTitle: "The Economics of Higher Education",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Policy",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC200A",
          courseTitle: "Introduction to Data Analysis and Interpretation",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC445",
          courseTitle: "Transforming Education through Entrepreneurship",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Organization",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "EDUC211A",
          courseTitle: "Maker Tools Learning Lab",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC209A",
          courseTitle: "Policy, Organization, and Leadership Studies Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC117",
          courseTitle:
            "Research and Policy on Postsecondary Access (EDUC 417, PUBLPOL 117, PUBLPOL 217A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Policy",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 18,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],

      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
  ],
  // icoloma2: [
  //   {
  //     year: "2023-2024",
  //     quarter: "Autumn",
  //     courses: [
  //       {
  //         unitsMax: 3,
  //         unitsMin: 3,
  //         courseNumber: "EDUC206A",
  //         courseTitle:
  //           "Applied Research Methods in International and Comparative Education I: Introduction",
  //         courseUnits: 3,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 3,
  //         unitsMin: 3,
  //         courseNumber: "EDUC202",
  //         courseTitle:
  //           "Introduction to International and Comparative Education",
  //         courseUnits: 3,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 5,
  //         unitsMin: 5,
  //         courseNumber: "EDUC306A",
  //         courseTitle: "Economics of Education in the Global Economy",
  //         courseUnits: 5,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 4,
  //         unitsMin: 3,
  //         courseNumber: "EDUC400A",
  //         courseTitle: "Introduction to Statistical Methods in Education",
  //         courseUnits: 4,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 4,
  //         unitsMin: 3,
  //         courseNumber: "EDUC423A",
  //         courseTitle:
  //           "Introduction to Education Data Science: Data Processing (SOC 302A)",
  //         courseUnits: 3,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       //{ num: "", title: "", letter: "", units: 0 },
  //     ],
  //     total: 18,
  //   },
  //   {
  //     year: "2023-2024",
  //     quarter: "Winter",
  //     courses: [
  //       {
  //         unitsMax: 3,
  //         unitsMin: 1,
  //         courseNumber: "EDUC206B",
  //         courseTitle:
  //           "Applied Research Methods in International and Comparative Education II: Master's Paper Proposal",
  //         courseUnits: 3,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 4,
  //         unitsMin: 4,
  //         courseNumber: "EDUC200B",
  //         courseTitle: "Introduction to Qualitative Research Methods",
  //         courseUnits: 4,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 4,
  //         unitsMin: 3,
  //         courseNumber: "EDUC208B",
  //         courseTitle: "Curriculum Construction",
  //         courseUnits: 3,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 5,
  //         unitsMin: 4,
  //         courseNumber: "EDUC306D",
  //         courseTitle:
  //           "Global Social Change, Sustainable Development, and Education (EDUC 136, SOC 231, SUSTAIN 226)",
  //         courseUnits: 4,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 2,
  //         unitsMin: 1,
  //         courseNumber: "EDUC401B",
  //         courseTitle: "Mini Courses in Methodology: Stata",
  //         courseUnits: 1,
  //         gradingOption: "S/NC",
  //         programRequirement: "",
  //         availableGradingOptions: ["S/NC"],
  //       },
  //       {
  //         unitsMax: 3,
  //         unitsMin: 1,
  //         courseNumber: "EDUC387A",
  //         courseTitle: "World Education Reform Database (WERD) Lab",
  //         courseUnits: 3,
  //         gradingOption: "S/NC",
  //         programRequirement: "",
  //         availableGradingOptions: ["S/NC"],
  //       },
  //     ],
  //     total: 18,
  //   },
  //   {
  //     year: "2023-2024",
  //     quarter: "Spring",
  //     courses: [
  //       {
  //         unitsMax: 3,
  //         unitsMin: 1,
  //         courseNumber: "EDUC206C",
  //         courseTitle:
  //           "Applied Research Methods in ICE III: Data Collection and Analysis",
  //         courseUnits: 2,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 4,
  //         unitsMin: 3,
  //         courseNumber: "EDUC202I",
  //         courseTitle: "International Education Policy Workshop (EDUC 102I)",
  //         courseUnits: 3,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 3,
  //         unitsMin: 2,
  //         courseNumber: "EDUC278",
  //         courseTitle: "Introduction to Program Evaluation",
  //         courseUnits: 3,
  //         gradingOption: "Letter",
  //         programRequirement: "",
  //         availableGradingOptions: ["Letter", "Credit/No Credit"],
  //       },
  //       {
  //         unitsMax: 3,
  //         unitsMin: 1,
  //         courseNumber: "EDUC387A",
  //         courseTitle: "World Education Reform Database (WERD) Lab",
  //         courseUnits: 2,
  //         gradingOption: "S/NC",
  //         programRequirement: "",
  //         availableGradingOptions: ["S/NC"],
  //       },
  //       //{ num: "", title: "", letter: "", units: 0 },
  //       //{ num: "", title: "", letter: "", units: 0 },
  //     ],
  //     total: 10,
  //   },
  //   {
  //     year: "2023-2024",
  //     quarter: "Summer",
  //     courses: [
  //       {
  //         courseNumber: "EDUC206D",
  //         courseTitle: "",
  //         courseUnits: 0,
  //         gradingOption: "",
  //         programRequirement: "",
  //       },
  //       //{ num: "", title: "", letter: "" },
  //       //{ num: "", title: "", letter: "" },
  //     ],
  //     total: 0,
  //   },
  // ],
  jcolin18: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 2,
          courseNumber: "EDUC229A",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC421",
          courseTitle:
            "Powerful Ideas for Learning Sciences and Technology Design: Sociocultural Practices of the Blues",
          courseUnits: 3,
          gradingOption: "S/NC",
          programRequirement: "Learning Theories",
          availableGradingOptions: ["S/NC"],
        },
        {
          additionalInfo: "Tiny Treks",
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship Workshop",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Internship",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 6,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "DESIGN282",
          courseTitle: "Designing with Machine Learning",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Perspectives on Technology",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC482",
          courseTitle:
            "Design to Equip Learners in Under-Resourced Communities (DESIGN 294)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Design of Learning Experiences",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "EDUC468",
          courseTitle: "Robotics, AI and Design of Future Education (ME 268)",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 7,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "DESIGN255",
          courseTitle: "The Design of Data",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Design Process",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "ENGR311B",
          courseTitle: "Designing the Professional",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "SUSTAIN330",
          courseTitle: "Leading Environmental Sustainability by Design",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 7,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        //{ requirement: "", num: "", title: "", letter: "" },
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC200A",
          courseTitle: "Introduction to Data Analysis and Interpretation",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Research Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          additionalInfo: "AI @ Planet",
          courseNumber: "EDUC215",
          courseTitle: "Education Internship Workshop",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC364",
          courseTitle: "Cognition and Learning",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 7,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC237",
          courseTitle: "Learning, Making, Crafting, & Creating",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning Content",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          additionalInfo: "Design Your Life",
          courseNumber: "EDUC229B",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC208B",
          courseTitle: "Curriculum Construction",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Design of Learning Experiences",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 7,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC229C",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STRAMGT345",
          courseTitle: "Impact: Taking Social Innovation to Scale",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 2,
          courseNumber: "EDUC371",
          courseTitle: "Social Psychology and Social Change (PSYCH 265)",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 7,
    },
    {
      year: "2024-2025",
      quarter: "Summer",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 2,
          courseNumber: "EDUC229D",
          courseTitle: "Learning Design and Technology Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "EDUC211A",
          courseTitle: "Makers Tool",
          courseUnits: 1,
          gradingOption: "Credit/No Credit",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 4,
    },
  ],
  // this one imports oddly, it imports but doesn't save, and shows trashcans
  julialdf: [
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC202",
          courseTitle:
            "Introduction to International and Comparative Education",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC206A",
          courseTitle:
            "Applied Research Methods in International and Comparative Education I: Introduction",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 4,
          courseNumber: "EDUC256",
          courseTitle:
            "Psychological and Educational Resilience Among Children and Youth (HUMBIO 149)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC303",
          courseTitle: "Educational Opportunity Research Lab (SOC 303)",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 5,
          unitsMin: 5,
          courseNumber: "EDUC306A",
          courseTitle: "Economics of Education in the Global Economy",
          courseUnits: 5,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC339",
          courseTitle: "Advanced Topics in Quantitative Policy Analysis",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC404",
          courseTitle:
            "Topics in Brazilian Education: Public Policy and Innovation for the 21st Century",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 18,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "EDUC200B",
          courseTitle: "Introduction to Qualitative Research Methods",
          courseUnits: 4,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "EDUC203",
          courseTitle:
            "Using International Test Results in Educational Research",
          courseUnits: 4,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC206B",
          courseTitle:
            "Applied Research Methods in International and Comparative Education II: Master's Paper Proposal",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC208B",
          courseTitle: "Curriculum Construction",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 4,
          courseNumber: "EDUC306D",
          courseTitle:
            "Global Social Change, Sustainable Development, and Education (EDUC 136, SOC 231, SUSTAIN 226)",
          courseUnits: 4,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC365",
          courseTitle: "Social, Emotional, and Personality Development",
          courseUnits: 3,
          gradingOption: "S/NC",
          programRequirement: "",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 19,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC206C",
          courseTitle:
            "Applied Research Methods in ICE III: Data Collection and Analysis",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC326",
          courseTitle: "Advanced Regression Analysis (SOC 384)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC404",
          courseTitle:
            "Topics in Brazilian Education: Public Policy and Innovation for the 21st Century",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC479",
          courseTitle:
            "Entering the Backstage of Education Policy Making (PUBLPOL 379)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 8,
    },
    {
      year: "2024-2025",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
  ],
  lucilang: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423A",
          courseTitle:
            "Introduction to Education Data Science: Data Processing (SOC 302A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC430A",
          courseTitle: "Experimental Research Design and Analysis",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC391",
          courseTitle: "Engineering Education and Online Learning (ENGR 391)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423B",
          courseTitle:
            "Introduction to Education Data Science: Data Analysis (SOC 302B)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC430B",
          courseTitle:
            "Quasi-Experimental Research Design & Analysis (SOC 258B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS124",
          courseTitle:
            "From Languages to Information (LINGUIST 180, LINGUIST 280)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC252",
          courseTitle: "Introduction to Psychometrics",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 16,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC234",
          courseTitle: "Curiosity in Artificial Intelligence (PSYCH 240A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "CS224C",
          courseTitle: "NLP for Computational Social Science",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 2,
          courseNumber: "EDUC252L",
          courseTitle: "Introduction to Psychometrics - Lab",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS221",
          courseTitle: "Artificial Intelligence: Principles and Techniques",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 2,
          courseNumber: "EDUC473",
          courseTitle: "Empowering Educators via Language Technology (CS 293)",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC364",
          courseTitle: "Cognition and Learning",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC254",
          courseTitle: "Digital Learning Design Workshop",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship Workshop",
          courseUnits: 3,
          gradingOption: "S/NC",
          programRequirement: "Core",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 8,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 3,
    },
  ],
  khaulat: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423A",
          courseTitle:
            "Introduction to Education Data Science: Data Processing (SOC 302A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC200A",
          courseTitle: "Introduction to Data Analysis and Interpretation",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "DANCE50",
          courseTitle: "Contemporary Choreography",
          courseUnits: 1,
          gradingOption: "Credit/No Credit",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 9,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423B",
          courseTitle:
            "Introduction to Education Data Science: Data Analysis (SOC 302B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC399A",
          courseTitle: "Designing Surveys",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "MS&E135",
          courseTitle: "Networks",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC254",
          courseTitle: "Digital Learning Design Workshop",
          courseUnits: 2,
          gradingOption: "S/NC",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC254",
          courseTitle: "Digital Learning Design Workshop",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship Workshop",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Core",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "ENGR311D",
          courseTitle:
            "Portfolio to Professional: Supporting the Development of Digital Presence Through ePortfolios",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 2,
          unitsMin: 2,
          courseNumber: "GSBGEN622",
          courseTitle: "Presentation and Communication Skills for Academics",
          courseUnits: 2,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC316",
          courseTitle: "Social Network Methods (SOC 369)",
          courseUnits: 4,
          gradingOption: "Credit/No Credit",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC333A",
          courseTitle:
            "Introduction to Learning Sciences: Understanding Learning and Learning Environments",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 2,
          courseNumber: "ARTSTUDI132",
          courseTitle: "Storytelling Through Light",
          courseUnits: 2,
          gradingOption: "Credit/No Credit",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STATS202",
          courseTitle: "Data Mining and Analysis",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS124",
          courseTitle:
            "From Languages to Information (LINGUIST 180, LINGUIST 280)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          additionalInfo: "Candace Thille",
          unitsMax: 15,
          unitsMin: 1,
          courseNumber: "EDUC180",
          courseTitle: "Directed Reading in Education",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 8,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 1,
          courseNumber: "EDUC407",
          courseTitle: "Lytics Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "CS224C",
          courseTitle: "NLP for Computational Social Science",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 8,
    },
  ],
  mhoyl: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423A",
          courseTitle:
            "Introduction to Education Data Science: Data Processing (SOC 302A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC400A",
          courseTitle: "Introduction to Statistical Methods in Education",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC391",
          courseTitle: "Engineering Education and Online Learning (ENGR 391)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423B",
          courseTitle:
            "Introduction to Education Data Science: Data Analysis (SOC 302B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "ENGR248",
          courseTitle: "Principled Entrepreneurial Decisions (ENGR 148)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS124",
          courseTitle:
            "From Languages to Information (LINGUIST 180, LINGUIST 280)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC234",
          courseTitle: "Curiosity in Artificial Intelligence (PSYCH 240A)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS221",
          courseTitle: "Artificial Intelligence: Principles and Techniques",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224N",
          courseTitle:
            "Natural Language Processing with Deep Learning (LINGUIST 284, SYMSYS 195N)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "EDUC215",
          courseTitle: "Education Internship at Organization: ",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 1,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC449",
          courseTitle:
            "Design for Learning: Generative AI for Collaborative Learning (CS 498D, DESIGN 292)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 2,
          courseNumber: "EDUC473",
          courseTitle: "Empowering Educators via Language Technology (CS 293)",
          courseUnits: 2,
          gradingOption: "Credit/No Credit",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STATS202",
          courseTitle: "Data Mining and Analysis",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 9,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC463",
          courseTitle:
            "Computer Vision for Education and Social Science Research (CS 432)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "EDUC468",
          courseTitle: "Robotics, AI and Design of Future Education (ME 268)",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 2,
          unitsMin: 2,
          courseNumber: "EDUC475",
          courseTitle:
            "Entrepreneurship in Education: A Comparative Analysis of the US and Emerging Markets",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC430B",
          courseTitle:
            "Quasi-Experimental Research Design & Analysis (SOC 258B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Causal methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "CS224C",
          courseTitle: "NLP for Computational Social Science",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "GSBGEN310",
          courseTitle:
            "Business and AI: Lessons from Entrepreneurs, Executives, and Investors",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "ECON293",
          courseTitle: "Machine Learning and Causal Inference",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Causal methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
  ],
  micheliu: [
    //{
    //  year: "2022-2023",
    //  quarter: "Autumn",
    //  courses: [
    //    { requirement: "", num: "", title: "", letter: "", units: 0 },
    //    { requirement: "", num: "", title: "", letter: "", units: 0 },
    //  ],
    //  total: 0,
    //},
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC391",
          courseTitle: "Engineering Education and Online Learning (ENGR 391)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "POLISCI450A",
          courseTitle: "Political Methodology I: Regression",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "CS106AX",
          courseTitle:
            "Programming Methodologies in JavaScript and Python (Accelerated)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "CS432",
          courseTitle:
            "Computer Vision for Education and Social Science Research (EDUC 463)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS124",
          courseTitle:
            "From Languages to Information (LINGUIST 180, LINGUIST 280)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC237",
          courseTitle: "Learning, Making, Crafting, & Creating",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC471",
          courseTitle: "The Development of Purpose Across the Lifespan",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS231N",
          courseTitle: "Deep Learning for Computer Vision",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EE104",
          courseTitle: "Introduction to Machine Learning (CME 107)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          additionalInfo: "TBD",
          courseNumber: "EDUC215",
          courseTitle: "Education Internship Workshop: TBD",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 1,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC464",
          courseTitle:
            "Measuring Learning in the Brain (NEPR 464, PSYCH 279, SYMSYS 195M)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STATS202",
          courseTitle: "Data Mining and Analysis",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC364",
          courseTitle: "Cognition and Learning",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC252",
          courseTitle: "Introduction to Psychometrics",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC135",
          courseTitle:
            "Designing Research-Based Interventions to Solve Global Health Problems (AFRICAST 135, AFRICAST 235, EDUC 335, EPI 235, HUMBIO 26, MED 235)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS229",
          courseTitle: "Machine Learning (STATS 229)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC321",
          courseTitle:
            "Nonprofits, Philanthropy & Society (PUBLPOL 321, SOC 321)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224N",
          courseTitle:
            "Natural Language Processing with Deep Learning (LINGUIST 284, SYMSYS 195N)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC147",
          courseTitle: "Stanford and Its Worlds: 1885-present (HISTORY 58E)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
  ],
  millsp: [
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC209A",
          courseTitle: "Policy, Organization, and Leadership Studies Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC200A",
          courseTitle: "Introduction to Data Analysis and Interpretation",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC382",
          courseTitle:
            "Holistic College Student Development: Theory and Practices (EDUC 182, LIFE 182)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC461",
          courseTitle:
            "Community Engaged Psychology and Education Field Experience (PSYCH 161)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "SUST210",
          courseTitle:
            "Pursuing Sustainability: Managing Complex Social Environmental Systems",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "DESIGN231",
          courseTitle: "Graduate Design Research Techniques",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "EDUC200B",
          courseTitle: "Introduction to Qualitative Research Methods",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 18,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC209B",
          courseTitle: "Policy, Organization, and Leadership Studies Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC271",
          courseTitle: "Education Policy in the United States",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Policy",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 4,
          courseNumber: "EDUC306D",
          courseTitle:
            "Global Social Change, Sustainable Development, and Education (EDUC 136, SOC 231, SUSTAIN 226)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Policy",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC254",
          courseTitle: "Digital Learning Design Workshop",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "CHPR113",
          courseTitle:
            "Healthy/Sustainable Food Systems: Maximum Sustainability across Health, Economics, and Environment (HUMBIO 113S)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "SUST220",
          courseTitle: "Case Studies in Leading Change for Sustainability",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 18,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC209C",
          courseTitle: "Policy, Organization, and Leadership Studies Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Seminar",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC255",
          courseTitle: "Mission and Money in Education",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Organization",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "SUSTAIN248",
          courseTitle:
            "Environmental Governance and Climate Resilience (CEE 265F, POLISCI 227B, PUBLPOL 265F)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "DESIGN368",
          courseTitle: "Leadership Lab (MS&E 489)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "ENGR231",
          courseTitle: "Transformative Design",
          courseUnits: 3,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "DESIGN235",
          courseTitle:
            "View from the Future:  Speaker Series with Industry Pioneers Designing the Future",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Elective",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 16,
    },
  ],
  mlchrzan: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC400A",
          courseTitle: "Introduction to Statistical Methods in Education",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423A",
          courseTitle:
            "Introduction to Education Data Science: Data Processing (SOC 302A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC440",
          courseTitle:
            "(Re)Meditating Systems Change: Disability, Language & Difference (CSRE 340, PEDS 240)",
          courseUnits: 3,
          gradingOption: "S/NC",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423B",
          courseTitle:
            "Introduction to Education Data Science: Data Analysis (SOC 302B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC430B",
          courseTitle:
            "Quasi-Experimental Research Design & Analysis (SOC 258B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Causal methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC252",
          courseTitle: "Introduction to Psychometrics",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC326",
          courseTitle: "Advanced Regression Analysis (SOC 384)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "CS224C",
          courseTitle: "NLP for Computational Social Science",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC316",
          courseTitle: "Social Network Methods (SOC 369)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship at Organization: ",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Core",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 1,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224W",
          courseTitle: "Machine Learning with Graphs",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS377G",
          courseTitle: "Designing Serious Games",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC225",
          courseTitle: "What Works in Education?",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC271",
          courseTitle: "Education Policy in the United States",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224N",
          courseTitle:
            "Natural Language Processing with Deep Learning (LINGUIST 284, SYMSYS 195N)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC237",
          courseTitle: "Learning, Making, Crafting, & Creating",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STATS361",
          courseTitle: "Causal Inference",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Causal methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC234",
          courseTitle: "Curiosity in Artificial Intelligence (PSYCH 240A)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 9,
    },
  ],
  mnozaki: [
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423A",
          courseTitle:
            "Introduction to Education Data Science: Data Processing (SOC 302A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 3,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423B",
          courseTitle:
            "Introduction to Education Data Science: Data Analysis (SOC 302B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 3,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2025-2026",
      quarter: "Autumn",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2025-2026",
      quarter: "Winter",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2025-2026",
      quarter: "Spring",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
  ],
  ontapari: [
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC206A",
          courseTitle:
            "Applied Research Methods in International and Comparative Education I: Introduction",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC200A",
          courseTitle: "Introduction to Data Analysis and Interpretation",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC202",
          courseTitle:
            "Introduction to International and Comparative Education",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 5,
          courseNumber: "EDUC306A",
          courseTitle: "Economics of Education in the Global Economy",
          courseUnits: 5,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 1,
          unitsMin: 1,
          courseNumber: "EDUC306Y",
          courseTitle:
            "Economic Support Seminar for Education and Economic Development",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 4,
          unitsMin: 1,
          courseNumber: "EDUC352A",
          courseTitle: "Introduction to Research-Practice Partnerships",
          courseUnits: 2,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 17,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "EDUC200B",
          courseTitle: "Introduction to Qualitative Research Methods",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC306B",
          courseTitle: "Global Education Policy & Organization (PUBLPOL 316)",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC206B",
          courseTitle:
            "Applied Research Methods in International and Comparative Education II: Master's Paper Proposal",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC208B",
          courseTitle: "Curriculum Construction",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "EDUC401B",
          courseTitle: "Mini Courses in Methodology: Stata",
          courseUnits: 2,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 15,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC206C",
          courseTitle:
            "Applied Research Methods in ICE III: Data Collection and Analysis",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC202I",
          courseTitle: "International Education Policy Workshop (EDUC 102I)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship at Organization: ",
          courseUnits: 2,
          gradingOption: "S/NC",
          programRequirement: "",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 15,
          unitsMin: 1,
          courseNumber: "EDUC190",
          courseTitle: "Directed Research in Education: Loyalka. P",
          courseUnits: 2,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 1,
          courseNumber: "ORALCOMM215",
          courseTitle: "Voice Workshop (ORALCOMM 115)",
          courseUnits: 1,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC275",
          courseTitle: "Leading Change in Schools",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 13,
    },
    {
      year: "2024-2025",
      quarter: "Summer",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC206D",
          courseTitle:
            "Applied Research Methods in International and Comparative Education IV: Master's Paper Workshop",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 15,
          unitsMin: 1,
          courseNumber: "EDUC190",
          courseTitle: "Directed Research in Education: ",
          courseUnits: 2,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship at Organization: ",
          courseUnits: 1,
          gradingOption: "Credit/No Credit",
          programRequirement: "",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 6,
    },
  ],
  ruishich: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STATS202",
          courseTitle: "Data Mining and Analysis",
          courseUnits: 3,
          gradingOption: "Credit/No Credit",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC391",
          courseTitle: "Engineering Education and Online Learning (ENGR 391)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 15,
          unitsMin: 1,
          courseNumber: "EDUC190",
          courseTitle: "Directed Research in Education: Victor Lee",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS124",
          courseTitle:
            "From Languages to Information (LINGUIST 180, LINGUIST 280)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC463",
          courseTitle:
            "Computer Vision for Education and Social Science Research (CS 432)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 2,
          courseNumber: "EDUC475",
          courseTitle:
            "Entrepreneurship in Education: A Comparative Analysis of the US and Emerging Markets",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "CS224C",
          courseTitle: "NLP for Computational Social Science",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC316",
          courseTitle: "Social Network Methods (SOC 369)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC471",
          courseTitle: "The Development of Purpose Across the Lifespan",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 2,
          courseNumber: "EDUC473",
          courseTitle: "Empowering Educators via Language Technology (CS 293)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC320",
          courseTitle: "Sociology of Science (EDUC 120, SOC 330, STS 200Q)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224W",
          courseTitle: "Machine Learning with Graphs",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC365",
          courseTitle: "Social, Emotional, and Personality Development",
          courseUnits: 3,
          gradingOption: "S/NC",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224N",
          courseTitle:
            "Natural Language Processing with Deep Learning (LINGUIST 284, SYMSYS 195N)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC315A",
          courseTitle:
            "Introduction to CSCL: Computer-Supported Collaborative Learning (CS 498C)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS278",
          courseTitle: "Social Computing (SOC 174, SOC 274)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC234",
          courseTitle: "Curiosity in Artificial Intelligence (PSYCH 240A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 9,
    },
  ],
  samink: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 2,
          courseNumber: "EDUC473",
          courseTitle: "Empowering Educators via Language Technology (CS 293)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "CS329R",
          courseTitle:
            "Race and Natural Language Processing (LINGUIST 281A, PSYCH 257A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS324H",
          courseTitle: "History of Natural Language Processing",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC463",
          courseTitle:
            "Computer Vision for Education and Social Science Research (CS 432)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS278",
          courseTitle: "Social Computing (SOC 174, SOC 274)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC275",
          courseTitle: "Leading Change in Schools",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "e",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STATS202",
          courseTitle: "Data Mining and Analysis",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STATS209",
          courseTitle: "Introduction to Causal Inference",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Causal methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC200A",
          courseTitle: "Introduction to Data Analysis and Interpretation",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          additionalInfo: "ACUE",
          courseNumber: "EDUC215",
          courseTitle: "Education Internship Workshop",
          courseUnits: 1,
          gradingOption: "S/NC",
          programRequirement: "Core",
          availableGradingOptions: ["S/NC"],
        },
      ],
      total: 11,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 5,
          courseNumber: "PSYCH252",
          courseTitle: "Statistical Methods for Behavioral and Social Sciences",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC266",
          courseTitle: "Educational Neuroscience",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        //{ requirement: "", num: "", title: "", letter: "", units: 0 },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC316",
          courseTitle: "Social Network Methods (SOC 369)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Network science",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 5,
          courseNumber: "POLISCI355C",
          courseTitle: "Causal Inference for Social Science (POLISCI 150C)",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "Causal methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
  ],
  tracyli1: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "CS106A",
          courseTitle: "Programming Methodology",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423A",
          courseTitle:
            "Introduction to Education Data Science: Data Processing (SOC 302A)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC430A",
          courseTitle: "Experimental Research Design and Analysis",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Causal methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 14,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "CS106B",
          courseTitle: "Programming Abstractions",
          courseUnits: 5,
          gradingOption: "Credit/No Credit",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423B",
          courseTitle:
            "Introduction to Education Data Science: Data Analysis (SOC 302B)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "INTLPOL357",
          courseTitle: "Transitional Justice",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 15,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 15,
          unitsMin: 1,
          additionalInfo: "Jason Yeatman/ Rebecca Silverman",
          courseNumber: "EDUC190",
          courseTitle: "Directed Research in Education",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "LAW5103",
          courseTitle: "State-Building and the Rule of Law Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 4,
          courseNumber: "ARTSTUDI245",
          courseTitle: "Painting II",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 11,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          courseNumber: "",
          courseTitle: "",
          gradingOption: "",
          courseUnits: 0,
          programRequirement: "",
        },
      ],
      total: 0,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC464",
          courseTitle:
            "Measuring Learning in the Brain (NEPR 464, PSYCH 279, SYMSYS 195M)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "CS109",
          courseTitle: "Introduction to Probability for Computer Scientists",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 11,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC430B",
          courseTitle:
            "Quasi-Experimental Research Design & Analysis (SOC 258B)",
          courseUnits: 5,
          gradingOption: "Letter",
          programRequirement: "Causal methods",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS124",
          courseTitle:
            "From Languages to Information (LINGUIST 180, LINGUIST 280)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC370",
          courseTitle: "Parenting and Family Relationships in Childhood",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 15,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 5,
          unitsMin: 3,
          courseNumber: "EDUC326",
          courseTitle: "Advanced Regression Analysis (SOC 384)",
          courseUnits: 5,
          gradingOption: "Credit/No Credit",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 8,
    },
  ],
  ylzhao: [
    {
      year: "2023-2024",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS448B",
          courseTitle: "Data Visualization (EDUC 458, SYMSYS 195V)",
          courseUnits: 4,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC464",
          courseTitle:
            "Measuring Learning in the Brain (NEPR 464, PSYCH 279, SYMSYS 195M)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259A",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS124",
          courseTitle:
            "From Languages to Information (LINGUIST 180, LINGUIST 280)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259B",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC463",
          courseTitle:
            "Computer Vision for Education and Social Science Research (CS 432)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 2,
          courseNumber: "ME269",
          courseTitle: "Designing Learning and Making Environments",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2023-2024",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259C",
          courseTitle: "Education Data Science Seminar",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS224N",
          courseTitle:
            "Natural Language Processing with Deep Learning (LINGUIST 284, SYMSYS 195N)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "NLP",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 2,
          unitsMin: 2,
          courseNumber: "EDUC252L",
          courseTitle: "Introduction to Psychometrics - Lab",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Measurement",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC234",
          courseTitle: "Curiosity in Artificial Intelligence (PSYCH 240A)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Learning analytics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 9,
    },
    {
      year: "2023-2024",
      quarter: "Summer",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC215",
          courseTitle: "Education Internship at Organization: ",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 1,
    },
    {
      year: "2024-2025",
      quarter: "Autumn",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259D",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS229",
          courseTitle: "Machine Learning (STATS 229)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "STATS202",
          courseTitle: "Data Mining and Analysis",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Statistics",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC449",
          courseTitle:
            "Design for Learning: Generative AI for Collaborative Learning (CS 498D, DESIGN 292)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Winter",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259E",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 1,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "CS131",
          courseTitle: "Computer Vision: Foundations and Applications",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "EDUC423B",
          courseTitle:
            "Introduction to Education Data Science: Data Analysis (SOC 302B)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "EDUC234A",
          courseTitle: "Interactive and Embodied Learning (CS 422)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Education Foundation",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 10,
    },
    {
      year: "2024-2025",
      quarter: "Spring",
      courses: [
        {
          unitsMax: 3,
          unitsMin: 1,
          courseNumber: "EDUC259F",
          courseTitle: "Education Data Science Capstone Projects",
          courseUnits: 2,
          gradingOption: "Letter",
          programRequirement: "Core",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 3,
          unitsMin: 3,
          courseNumber: "DESIGN187N",
          courseTitle: "How to Shoot for the Moon (AA 107N)",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: 4,
          unitsMin: 3,
          courseNumber: "DESIGN255",
          courseTitle: "The Design of Data",
          courseUnits: 3,
          gradingOption: "Letter",
          programRequirement: "Elective",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      total: 8,
    },
  ],
  sartang: [
    {
      year: "2023-2024",
      total: 6,
      courses: [
        {
          unitsMax: "3",
          crossListed: false,
          courseUnits: "3",
          gradingOption: "S/NC",
          programRequirement: "",
          courseNumber: "EDUC393",
          courseTitle: "Proseminar: Education, Business, Politics",
          unitsMin: "3",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: "3",
          crossListed: true,
          courseUnits: "3",
          gradingOption: "Letter",
          programRequirement: "",
          courseNumber: "GSBGEN345",
          courseTitle: "Disruptions in Education",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      quarter: "Winter",
    },
    {
      year: "2023-2024",
      total: 2,
      courses: [
        {
          unitsMax: "2",
          crossListed: true,
          courseUnits: "2",
          gradingOption: "Letter",
          programRequirement: "",
          courseNumber: "STRAMGT537",
          courseTitle: "Leading Change in Public Education",
          unitsMin: "2",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          gradingOption: "",
          unitsMax: 1,
          courseUnits: 0,
          programRequirement: "",
          courseTitle: "",
          unitsMin: 1,
          availableGradingOptions: [],
        },
      ],
      quarter: "Spring",
    },
    {
      year: "2023-2024",
      total: 0,
      courses: [
        {
          unitsMax: 1,
          crossListed: false,
          courseUnits: 0,
          gradingOption: "",
          programRequirement: "",
          courseNumber: "",
          courseTitle: "",
          unitsMin: 1,
          availableGradingOptions: [],
        },
      ],
      quarter: "Summer",
    },
    {
      year: "2024-2025",
      total: 8,
      courses: [
        {
          unitsMax: "3",
          unitsMin: "3",
          courseUnits: "3",
          gradingOption: "Letter",
          courseNumber: "STRAMGT335",
          courseTitle: "Transforming Education through Entrepreneurship",
          crossListed: true,
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "1",
          gradingOption: "S/NC",
          courseUnits: "1",
          courseNumber: "EDUC211A",
          courseTitle: "Maker Tools Learning Lab",
          unitsMin: "1",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: "4",
          crossListed: false,
          courseUnits: "4",
          gradingOption: "Letter",
          courseNumber: "STRAMGT356",
          courseTitle: "Startup Garage: Design",
          unitsMin: "4",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      quarter: "Autumn",
    },
    {
      year: "2024-2025",
      total: 8,
      courses: [
        {
          unitsMax: "3",
          unitsMin: "3",
          courseUnits: "3",
          gradingOption: "Letter",
          courseNumber: "EDUC271",
          courseTitle: "Education Policy in the United States",
          crossListed: false,
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "4",
          gradingOption: "Letter",
          courseUnits: "4",
          courseNumber: "EDUC337",
          courseTitle:
            "Race, Ethnicity, and Linguistic Diversity in Classrooms: Sociocultural Theory and Practices (AFRICAAM 106, CSRE 103B, EDUC 103B)",
          unitsMin: "4",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "3",
          gradingOption: "S/NC",
          courseUnits: "1",
          courseNumber: "EDUC215",
          programRequirement: "",
          courseTitle: "Education Internship at Uppercase",
          unitsMin: "1",
          availableGradingOptions: ["S/NC"],
        },
      ],
      quarter: "Winter",
    },
    {
      year: "2024-2025",
      total: 11,
      courses: [
        {
          unitsMax: "2",
          unitsMin: "2",
          courseUnits: "2",
          gradingOption: "Letter",
          courseNumber: "GSBGEN591",
          courseTitle:
            "Entrepreneurship and Innovation in Education Technology Seminar",
          crossListed: true,
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "4",
          gradingOption: "Letter",
          courseUnits: "4",
          courseNumber: "EDUC275",
          courseTitle: "Leading Change in Schools",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "5",
          gradingOption: "Letter",
          courseUnits: "5",
          courseNumber: "EDUC212",
          courseTitle:
            "Urban Education (AFRICAAM 112, CSRE 112X, EDUC 112, SOC 129X, SOC 229X, URBANST 115)",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      quarter: "Spring",
    },
  ],
  xuy30: [
    {
      year: "2024-2025",
      total: 17,
      courses: [
        {
          unitsMax: "4",
          gradingOption: "Credit/No Credit",
          courseUnits: "4",
          courseNumber: "CS377G",
          programRequirement: "Design Process",
          courseTitle: "Designing Serious Games",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "3",
          gradingOption: "Letter",
          courseUnits: "3",
          courseNumber: "EDUC391",
          programRequirement: "Design of Learning Experiences",
          courseTitle: "Engineering Education and Online Learning (ENGR 391)",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "3",
          gradingOption: "Letter",
          courseUnits: "3",
          courseNumber: "EDUC333A",
          programRequirement: "Learning Theories",
          courseTitle:
            "Introduction to Learning Sciences: Understanding Learning and Learning Environments",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "3",
          gradingOption: "Letter",
          courseUnits: "3",
          courseNumber: "EDUC229A",
          programRequirement: "Seminar",
          courseTitle: "Learning Design and Technology Seminar",
          unitsMin: "2",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "4",
          gradingOption: "Letter",
          courseUnits: "4",
          courseNumber: "EDUC200B",
          programRequirement: "Research Methods",
          courseTitle: "Introduction to Qualitative Research Methods",
          unitsMin: "4",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      quarter: "Autumn",
    },
    {
      year: "2024-2025",
      total: 17,
      courses: [
        {
          unitsMax: "3",
          gradingOption: "Letter",
          courseUnits: "3",
          courseNumber: "EDUC237",
          programRequirement: "Learning Content",
          courseTitle: "Learning, Making, Crafting, & Creating",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "4",
          gradingOption: "Letter",
          courseUnits: "4",
          courseNumber: "EDUC208B",
          programRequirement: "Design of Learning Experiences",
          courseTitle: "Curriculum Construction",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "3",
          gradingOption: "Letter",
          courseUnits: "3",
          courseNumber: "EDUC229B",
          programRequirement: "Seminar",
          courseTitle: "Learning Design and Technology Seminar",
          unitsMin: "1",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "4",
          gradingOption: "Letter",
          courseUnits: "4",
          courseNumber: "EDUC473",
          programRequirement: "Perspectives on Technology",
          courseTitle: "Empowering Educators via Language Technology (CS 293)",
          unitsMin: "2",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "3",
          gradingOption: "Letter",
          courseUnits: "3",
          courseNumber: "EDUC342",
          programRequirement: "Design of Learning Experiences",
          courseTitle: "Child Development and New Technologies",
          unitsMin: "3",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      quarter: "Winter",
    },
    {
      year: "2024-2025",
      total: 9,
      courses: [
        {
          unitsMax: "3",
          gradingOption: "Letter",
          courseUnits: "3",
          courseNumber: "EDUC229C",
          programRequirement: "Seminar",
          courseTitle: "Learning Design and Technology Seminar",
          unitsMin: "1",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "3",
          gradingOption: "S/NC",
          courseUnits: "3",
          courseNumber: "EDUC215",
          programRequirement: "Internship",
          courseTitle: "Education Internship at Organization: NA",
          unitsMin: "1",
          availableGradingOptions: ["S/NC"],
        },
        {
          unitsMax: "3",
          gradingOption: "Letter",
          courseUnits: "3",
          courseNumber: "EDUC171",
          programRequirement: "Learning Content",
          courseTitle: "Preschool Counts: Engaging Young Children in Math",
          unitsMin: "1",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
      ],
      quarter: "Spring",
    },
    {
      year: "2024-2025",
      total: 5,
      courses: [
        {
          unitsMax: "5",
          gradingOption: "Letter",
          courseUnits: "2",
          courseNumber: "EDUC229D",
          programRequirement: "Seminar",
          courseTitle: "Learning Design and Technology Seminar",
          unitsMin: "2",
          availableGradingOptions: ["Letter", "Credit/No Credit"],
        },
        {
          unitsMax: "3",
          gradingOption: "S/NC",
          courseUnits: "3",
          courseNumber: "EDUC215",
          programRequirement: "Internship",
          courseTitle: "Education Internship at Organization: NA",
          unitsMin: "1",
          availableGradingOptions: ["S/NC"],
        },
      ],
      quarter: "Summer",
    },
  ],
};

// List of email prefixes
const emailPrefixes = [
  "aaismail",
  "absparks",
  //"amock",
  "antonio0",
  "changlh",
  "eparish",
  //"icoloma2",
  "julialdf",
  "jcolin18",
  "khaulat",
  "lucilang",
  "micheliu",
  "millsp",
  "mhoyl",
  "mlchrzan",
  "mnozaki",
  "ontapari",
  "ruishich",
  "samink",
  "tracyli1",
  "ylzhao",
  "sartang",
  "xuy30",
];

const emailDataMap = new Map();

emailPrefixes.forEach((prefix: string) => {
  const email = `${prefix}@stanford.edu`;
  const data = emailData[prefix] || [];
  emailDataMap.set(email, data);
});

export const legacyEmailAddressesHaveRows = emailPrefixes.map(
  (prefix) => `${prefix}@stanford.edu`,
);

//export const hasEmail = (email: string) => {
//  return emailDataMap.has(email);
//};

export const getEmailData = (email: string) => {
  return emailDataMap.get(email) || null;
};
