import { XMarkIcon } from "@heroicons/react/24/outline";

export const AlertErrorValidations = ({
  messageListAlertErrors,
}: {
  messageListAlertErrors: any;
}) => {
  return (
    <div
      className="daisy-alert daisy-alert-error bg-red-200 w-full py-30 pl-30 flex"
      id="alertDivErrorValidations"
    >
      <span className="flex-grow text-left">
        Please evaluate and address the following issues before submitting:
        <div className="mt-18">
          {messageListAlertErrors && (
            <ul>
              {messageListAlertErrors.map((message: string, index: number) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          )}
        </div>
      </span>
      <button
        className="text-gray-200"
        type="button"
        onClick={() => {
          console.log("you've been clicked");
          const alertDivErrorValidationsClassList = document.getElementById(
            "alertDivErrorValidations",
          )?.classList;
          alertDivErrorValidationsClassList?.add("hidden");
        }}
      >
        <XMarkIcon className="h-34 w-34 text-red-800" />
      </button>
    </div>
  );
};
export const AlertInfoValidations = ({
  messageListAlertInfo,
}: {
  messageListAlertInfo: any;
}) => {
  return (
    <div
      className="daisy-alert daisy-alert-error bg-yellow-200 w-full py-30 pl-30 flex mt-20"
      id="alertDivInfoValidations"
    >
      <span className="flex-grow text-left">
        Please note that while LDT students are permitted to enroll in 11-18
        units during Spring Quarter, most choose to take a reduced load of 8-10
        units, which reduces the cost of tuition. Please consider which option
        works best for you before submitting.
        <div className="mt-18">
          {messageListAlertInfo && (
            <ul>
              {messageListAlertInfo.map((message: string, index: number) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          )}
        </div>
      </span>
      <button
        className="text-gray-200"
        type="button"
        onClick={() => {
          console.log("you've been clicked");
          const alertDivInfoValidationsClassList = document.getElementById(
            "alertDivInfoValidations",
          )?.classList;
          alertDivInfoValidationsClassList?.add("hidden");
        }}
      >
        <XMarkIcon className="h-34 w-34 text-yellow-800" />
      </button>
    </div>
  );
};

export const AlertErrorBasic = () => {
  return (
    <>
      <div
        className="daisy-alert daisy-alert-error justify-between w-6/12 hidden"
        id="alertDivBasic"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-current shrink-0 h-16 w-16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <span className="">
          Please evaluate the empty input field alerts in the page
        </span>
        <button
          className="text-gray-200"
          onClick={() => {
            const alertDivBasicClassList =
              document.getElementById("alertDivBasic")?.classList;
            if (alertDivBasicClassList?.contains("flex")) {
              alertDivBasicClassList?.remove("flex");
              alertDivBasicClassList?.add("hidden");
            }
          }}
        >
          <XMarkIcon className="h-14 w-14 text-red-800" />
        </button>
      </div>
    </>
  );
};
