import FooterStanford from "../components/FooterStanford";
import HeaderStanford from "../components/HeaderStanford";

//import { useState, useEffect, useContext } from "react";
import { useEffect, useContext } from "react";
// import { signInWithRedirect, getRedirectResult } from "firebase/auth";
// import { auth } from "../../firebase.config.js";
// import { onAuthStateChanged } from "firebase/auth";
// https://firebase.google.com/docs/auth/web/google-signin
import { SessionContext } from "../components/SessionContext";
import { handleSignIn } from "../extras/auxiliary_functions";

import { baseEmailDomain } from "../extras/constant_values";

const Login = () => {
  //const [userDisplayName, setUserDisplayName] = useState("");
  //const [userEmail, setUserEmail] = useState("");

  const { userData } = useContext(SessionContext);

  useEffect(() => {
    console.log(userData);
  }, [userData]); // Run effect when user changes

  return (
    <div className="flex flex-col w-full min-h-screen">
      <HeaderStanford />

      <div className="centered-container w-full grow flex flex-col md:flex-row rs-py-2 overflow-x-hidden">
        {/* <div className="mx-auto w-full lg:w-3/4"> */}
        <div className="mx-auto w-full">
          <article id="text__paragraphs" className="rs-mb-5">
            <div>
              {/* Display user info if available */}
              {userData.displayName && (
                <p>Display Name: {userData.displayName}</p>
              )}
              {userData.email && <p>Email: {userData.email}</p>}
              {/* Button to trigger sign-in */}
              <button
                onClick={() => handleSignIn()}
                className="button"
                type="button"
              >
                {baseEmailDomain === "gmail.com" ? " Google Gmail Login" : ""}
                {baseEmailDomain === "stanford.edu" ? " Stanford Login" : ""}
              </button>
            </div>
          </article>
        </div>
      </div>
      <FooterStanford />
    </div>
  );
};

export default Login;
