import { apiUrlEducAllCourses, timeInMinutesToCache } from "./constant_values";
import { fetchGzipJSON } from "./auxiliary_functions";

export const intakeFormLoader = async () => {
  const now = new Date();
  let now_epoch = Math.round(now.getTime() / 1000);
  let time_interval = 60 * timeInMinutesToCache; // seconds times timeInMinutesToCache minutes
  let future_time_epoch = Math.round(now.getTime() / 1000) + time_interval;

  // https://bobbyhadz.com/blog/typescript-type-null-is-not-assignable-to-type-string
  let cachedData = JSON.parse(
    localStorage.getItem(apiUrlEducAllCourses) as string,
  );

  // is there cached json, now epoch:number < parsed response which is type number, as string to pass typescript check
  //console.log(JSON.parse(localStorage.getItem("explorecourses_expiry") as string))
  //console.log(typeof(JSON.parse(localStorage.getItem("explorecourses_expiry") as string)))
  if (cachedData) {
    console.log("cachedData true");
  }
  if (
    cachedData &&
    now_epoch <
      parseInt(
        JSON.parse(localStorage.getItem("explorecourses_expiry") as string),
        10,
      )
  ) {
    //console.log(cachedData);
    console.log("cached already set");
    return "cached already set";
  } else {
    try {
      const responseData = await fetchGzipJSON(apiUrlEducAllCourses);
      localStorage.setItem(apiUrlEducAllCourses, JSON.stringify(responseData));
      localStorage.setItem(
        "explorecourses_expiry",
        future_time_epoch.toString(),
      );
      console.log("fresh api call and local storage being set");
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }
};
