import React from "react";

import {
  programDifferentiatorHasCrossListed,
  programDifferentiatorHasProgramRequirement,
} from "../extras/auxiliary_functions";

export const TableHeader: React.FC<{ program: string }> = ({ program }) => (
  <>
    {/*--<h3>{program}</h3> */}
    <div className="flex flex-wrap items-center mt-10 mb-6 font-bold">
      <div className="w-1/12">Quarter</div>
      <div className="w-1/12">Year</div>
      <div className="w-1/12">Course #</div>
      <div className="w-3/12">Course Title</div>
      {programDifferentiatorHasCrossListed(program) && (
        <div className="w-1/12">Check If Cross-Listed</div>
      )}
      <div className="w-1/12">Grading Option</div>
      {programDifferentiatorHasProgramRequirement(program) && (
        <div className="w-1/12">Program Requirement</div>
      )}
      <div className="w-2/12">Units</div>
    </div>
  </>
);

export const TableHeaderNew: React.FC<{ program: string }> = ({ program }) => {
  switch (program) {
    case "iepa":
    case "ice":
    case "designed":
      return (
        <div className="flex flex-wrap items-center mt-10 mb-6 font-bold">
          <div className="w-2/12 pl-4">Course #</div>
          <div className="w-6/12 pl-2">Course Title</div>
          <div className="w-2/12" title="Grading option">Grading</div>
          <div className="w-2/12">Units</div>
        </div>
      );
    case "joint_ma_mba":
      return (
        <div className="flex flex-wrap items-center mt-10 mb-6 font-bold">
          <div className="w-2/12 pl-4">Course #</div>
          <div className="w-6/12 pl-2">Course Title</div>
          <div className="w-1/12" title="Grading option">Grading</div>
          <div className="w-1/12" title="Check if cross-listed">X-List</div>
          <div className="w-2/12">Units</div>
        </div>
      );
    default:
      return (
        <div className="flex flex-wrap items-center mt-10 mb-6 font-bold">
          <div className="w-2/12 pl-4">Course #</div>
          <div className="w-5/12 pl-2">Course Title</div>
          <div className="w-1/12" title="Grading option">Grading</div>
          <div className="w-2/12" title="Course requirement">Requirement</div>
          <div className="w-2/12">Units</div>
        </div>
      );
  }
};

export default TableHeader;
