import { ToastContainer, Slide } from "react-toastify";

const ToastConfig = () => {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={12000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        style={{
          //fontSize: "30px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </>
  );
};

export default ToastConfig;
