// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  connectAuthEmulator,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

import {
  getStorage,
  connectStorageEmulator,
  ref, // new from chatgpt
  getDownloadURL, // new from chatgpt
  uploadBytes,
} from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxDGHFZxPy6oE2BXFQB-G82pLC1IxM_VE",
  authDomain: "programproposal.gse.stanford.edu",
  projectId: "gse-program-proposal",
  storageBucket: "gse-program-proposal.appspot.com",
  messagingSenderId: "34616058788",
  appId: "1:34616058788:web:d579ac48d30da1fe541731",
  measurementId: "G-6DCG6GQPTZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app);
const db = getFirestore(app);

// Use emulator for localhost and 127.0.0.1
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  // Use this token for local app check
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = "7808251c-ed5a-46ef-8468-7d2b064749d2";
}

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    "6LfaUUoqAAAAAJMvmICXg6dGUbkU5mJx-V7kuxV4",
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

// Export and declare in firebase.config.d.ts
//export { storage, app, auth, functions, googleProvider }; //before;
//export { app, analytics, auth, functions, googleProvider, storage, db };
export {
  app,
  analytics,
  auth,
  functions,
  googleProvider,
  storage,
  db,
  ref,
  getDownloadURL,
  uploadBytes,
};
//export { storage, app, auth, functions, googleProvider, ref, getDownloadURL }; // after chatgpt
