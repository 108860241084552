import { ErrorMessage } from "formik";
//import { FormErrorProps } from "./interfaces";
import { GenericErrorProps } from "./interfaces";

// export const FormError: React.FC<FormErrorProps> = ({
//   errors,
//   rowIndex,
//   courseIndex,
//   fieldName,
//   submitCount,
// }) => {
//   // returning here to test
//   return;
//
//   //if (
//   //  submitCount > 0 &&
//   //  errors.rows &&
//   //  errors.rows[rowIndex] &&
//   //  errors.rows[rowIndex].courses &&
//   //  errors.rows[rowIndex]?.courses?.[courseIndex] &&
//   //  errors.rows[rowIndex]?.courses?.[courseIndex][fieldName]
//   //) {
//   //  return (
//   //    <div className="text-red-500 mt-1">
//   //      {errors.rows[rowIndex]?.courses?.[courseIndex][fieldName]}
//   //    </div>
//   //  );
//   //}
//   //return null;
// };

export const GenericError: React.FC<GenericErrorProps> = ({
  submitCount,
  fieldName,
}) =>
  submitCount > 0 && (
    <ErrorMessage
      name={fieldName}
      component="div"
      className="text-red-500 mt-1"
    />
  );
