import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState, useContext } from "react";
import { Link } from "react-router";
//import { last } from "lodash";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import FooterStanford from "../components/FooterStanford";
import HeaderStanford from "../components/HeaderStanford";
import SmartsheetPresentStatus from "../components/SmartsheetPresentStatus";
import { SessionContext } from "../components/SessionContext";
import SpinnerOverlay from "../components/SpinnerOverlay";

import {
  baseEmailDomain,
  programProgramAcronym,
  mappingProgramDirector,
} from "../extras/constant_values";
import {
  currentRoundName,
  fetchSmartsheetRelatedOnly,
  fetchUserdataPersonalOnly,
  getApprovalValues,
  getProgramVanityName,
  handleSignIn,
  programAcronymFromDataFullProgram,
  programDifferentiatorNoAdvisor,
  //toPacificTimeZone,
} from "../extras/auxiliary_functions";
import { SmartsheetRow } from "../extras/interfaces";
import { UserdataPersonalType } from "../extras/types";

import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase.config.js";
//import { AttachmentModal } from "../components/CustomModals";

// TODO: comebacktolater to do refactor and put the call to actions jsx in a seperate file once they are all polished

const Red_NeedToSubmit: React.FC<{
  userdataPersonal: any;
}> = ({ userdataPersonal }) => {
  const current_submission_round = userdataPersonal.submission_round;
  const program = userdataPersonal.program;
  // TODO: make this 1st, 2nd, 3rd round more globally available in the project
  let proposalTypeName =
    program === "eds" || program === "hcp"
      ? [
          "1st Round Proposal",
          "2nd Round Proposal",
          "3rd Round Proposal",
          "4th Round Proposal",
        ][current_submission_round - 1]
      : ["Preliminary Proposal", "Final Proposal"][
          current_submission_round - 1
        ];

  return (
    <>
      <div className="proposal-status daisy-alert bg-red-200 py-20 px-30 shadow-sm flex flex-col lg:flex-row lg:space-x-4">
        <div className="flex-1 flex flex-row">
          <InformationCircleIcon className="h-20 w-20 text-slate-500 stroke-[2px]" />
          <label htmlFor="round">Round:</label>
          <span id="round">{proposalTypeName}</span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Status:</label>
          <span id="status">Need to Submit</span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Action:</label>
          <span id="action">
            <Link to="/form">Start {proposalTypeName}</Link>
          </span>
        </div>
      </div>
    </>
  );
};

const Yellow_SubmittedWaitingForApproval: React.FC<{
  //submitted_pdf: string;
  //submitted_timestamp: string;
  mostRecentSubmission: any;
  //openModalAttachment: any;
  userdataPersonal: any;
}> = ({
  //submitted_pdf,
  //submitted_timestamp,
  mostRecentSubmission,
  //openModalAttachment,
  userdataPersonal,
}) => {
  const program = userdataPersonal.program;
  return (
    <>
      <div className="proposal-status daisy-alert bg-yellow-200 py-20 px-30 shadow-sm flex flex-col lg:flex-row lg:space-x-4">
        <div className="flex-1 flex flex-row">
          <InformationCircleIcon className="h-20 w-20 text-slate-500 stroke-[2px]" />
          <label htmlFor="round">Round:</label>
          <span id="round">
            {currentRoundName(mostRecentSubmission, program)}
          </span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Status:</label>
          <span id="status">Awaiting Approval</span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Action:</label>
          <span id="action">None</span>
        </div>
      </div>
    </>
  );
};

const Red_ReviseAndResubmit: React.FC<{
  mostRecentSubmission: any;

  userdataPersonal: any;
}> = ({ mostRecentSubmission, userdataPersonal }) => {
  const program = userdataPersonal.program;
  return (
    <>
      <div className="proposal-status daisy-alert bg-red-200 py-20 px-30 shadow-sm flex flex-col lg:flex-row lg:space-x-4">
        <div className="flex-1 flex flex-row">
          <InformationCircleIcon className="h-20 w-20 text-slate-500 stroke-[2px]" />
          <label htmlFor="round">Round:</label>
          <span id="round">
            {currentRoundName(mostRecentSubmission, program)}
          </span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Status:</label>
          <span id="status">Revise and Resubmit</span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Action:</label>
          <span id="action">
            <Link to="/form">Revise Proposal</Link>
          </span>
        </div>
      </div>
    </>
  );
};

const Yellow_PartiallyApprovedWaitingForApproval: React.FC<{
  mostRecentSubmission: any;

  userdataPersonal: any;
}> = ({ mostRecentSubmission, userdataPersonal }) => {
  const program = userdataPersonal.program;
  return (
    <>
      <div className="proposal-status daisy-alert bg-yellow-200 py-20 px-30 shadow-sm flex flex-col lg:flex-row lg:space-x-4">
        <div className="flex-1 flex flex-row">
          <InformationCircleIcon className="h-20 w-20 text-slate-500 stroke-[2px]" />
          <label htmlFor="round">Round:</label>
          <span id="round">
            {currentRoundName(mostRecentSubmission, program)}
          </span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Status:</label>
          <span id="status">Partially Approved</span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Action:</label>
          <span id="action">None</span>
        </div>
      </div>
    </>
  );
};

const Green_FullyApproved: React.FC<{
  mostRecentSubmission: any;
  userdataPersonal: any;
}> = ({ mostRecentSubmission, userdataPersonal }) => {
  const program = userdataPersonal.program;
  return (
    <>
      <div className="proposal-status daisy-alert bg-green-200 py-20 px-30 shadow-sm flex flex-col lg:flex-row lg:space-x-4">
        <div className="flex-1 flex flex-row">
          <InformationCircleIcon className="h-20 w-20 text-slate-500 stroke-[2px]" />
          <label htmlFor="round">Round:</label>
          <span id="round">
            {currentRoundName(mostRecentSubmission, program)}
          </span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Status:</label>
          <span id="status">Fully Approved</span>
        </div>
        <div className="flex-1">
          <label htmlFor="status">Action:</label>
          <span id="action">No Action Needed</span>
        </div>
      </div>
    </>
  );
};

export const CallToAction: React.FC<{
  smartsheetResponse: any;
  userdataPersonal: any;
  //openModalAttachment: any;
}> = ({ smartsheetResponse, userdataPersonal }) => {
  const current_submission_round = userdataPersonal.submission_round;
  const program = programProgramAcronym[userdataPersonal.program];

  if (smartsheetResponse.length === 0) {
    return <Red_NeedToSubmit userdataPersonal={userdataPersonal} />;
  } else {
    const smartsheetResponseReverse = smartsheetResponse.slice();

    //const [mostRecentSubmission, ...previousSubmissions] =
    //  smartsheetResponseReverse;
    const mostRecentSubmission = smartsheetResponseReverse[0];
    //const submitted_pdf = mostRecentSubmission["attachment_url_list"][0];
    //const submitted_pdf = last(
    //  mostRecentSubmission["attachment_url_list"],
    //) as string;
    const approvalValues = getApprovalValues(mostRecentSubmission);

    if (
      current_submission_round ===
      mostRecentSubmission.submission_round + 1
    ) {
      // the proposal should be incremented from the prior round to the newest round
      // for regular, eds and hcp, for regular this should only apply once, for eds and hcp test out more
      return <Red_NeedToSubmit userdataPersonal={userdataPersonal} />;
    } else if (approvalValues.length === 0) {
      // the proposal was just submitted and there is no feedback approval declines yet
      //const submitted_timestamp = toPacificTimeZone(
      //  mostRecentSubmission.created,
      //  "MMMM d, yyyy h:mmaaa",
      //);

      return (
        <Yellow_SubmittedWaitingForApproval
          //submitted_pdf={submitted_pdf}
          //submitted_timestamp={submitted_timestamp}
          mostRecentSubmission={mostRecentSubmission}
          //openModalAttachment={openModalAttachment}
          userdataPersonal={userdataPersonal}
        />
      );
    } else if (approvalValues.includes("Awaiting Approval")) {
      return (
        <Yellow_SubmittedWaitingForApproval
          //submitted_pdf={submitted_pdf}
          //submitted_timestamp={submitted_timestamp}
          mostRecentSubmission={mostRecentSubmission}
          //openModalAttachment={openModalAttachment}
          userdataPersonal={userdataPersonal}
        />
      );
    } else if (approvalValues.includes("Revise & Resubmit")) {
      // revise and resubmitteded declined proposal
      return (
        <Red_ReviseAndResubmit
          mostRecentSubmission={mostRecentSubmission}
          userdataPersonal={userdataPersonal}
        />
      );
    } else if (
      (approvalValues.length === 3 ||
        (programDifferentiatorNoAdvisor(program) &&
          approvalValues.length === 2)) &&
      approvalValues.every((item: string) => item === "Approved")
    ) {
      // fully approved
      return (
        <Green_FullyApproved
          mostRecentSubmission={mostRecentSubmission}
          userdataPersonal={userdataPersonal}
        />
      );
    } else {
      // its still a work in progress, some approvals but not totally approved
      return (
        <Yellow_PartiallyApprovedWaitingForApproval
          mostRecentSubmission={mostRecentSubmission}
          userdataPersonal={userdataPersonal}
        />
      );
    }
  }
};

const Status = () => {
  const { userData } = useContext(SessionContext);
  const [pageLoading, setPageLoading] = useState(true);
  //const [attachmentProcessing, setAttachmentProcessing] = useState(false);
  const [userdataPersonal, setUserdataPersonal] =
    useState<UserdataPersonalType | null>(null);
  const [smartsheetResponse, setSmartsheetResponse] = useState<SmartsheetRow[]>(
    [],
  );
  //const [isModalOpenAttachment, setModalOpenAttachment] =
  //  useState<boolean>(false);
  //const [files, setFiles] = useState<File[]>([]);
  //const [uploading, setUploading] = useState(false);

  //const [shouldPageRefreshContent, setShouldPageRefreshContent] =
  //  useState<boolean>(false);

  //const openModalAttachment = () => {
  //  setModalOpenAttachment(true);
  //};

  //const closeModalAttachment = () => {
  //  setModalOpenAttachment(false);
  //};

  useEffect(() => {
    // console.log("lets fetch data for the user");
    fetchUserdataPersonalOnly(setUserdataPersonal, userData);
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if userdataPersonal has been fetched and is available
        if (userdataPersonal) {
          const studentEmail = `${userdataPersonal.uid}@${baseEmailDomain}`;

          const programAcronym =
            programAcronymFromDataFullProgram(userdataPersonal);

          onSnapshot(
            collection(
              db,
              "smartsheet_form_submission",
              studentEmail,
              "id_form_submission",
            ),
            () => {
              // (snapshot) => {
              // const formData = snapshot.docs.map((doc) => doc.data());

              fetchSmartsheetRelatedOnly(
                studentEmail,
                programAcronym,
                setSmartsheetResponse,
              );
              // console.log("Form submissions: ", formData);
            },
            (error) => {
              console.error("Error fetching form submissions: ", error);
            },
          );

          // return () => unsub();

          await fetchSmartsheetRelatedOnly(
            studentEmail,
            programAcronym,
            setSmartsheetResponse,
          );

          setPageLoading(false);
        }
      } catch (error: any) {
        console.error(
          "There was a problem with the fetch operation:",
          error.message,
        );
      }
    };

    fetchData(); // Invoke the async function
  }, [userdataPersonal]);

  const excludedEmailsDirectors = new Set(
    Object.values(mappingProgramDirector).map((email) => email.split("@")[0]),
  );

  const sunetIds =
    (userdataPersonal?.advisorSunetid
      ? userdataPersonal.advisorSunetid
          .split(",")
          .map((item: string) =>
            item.trim() === "ssmith11" ? "sannesmith" : item.trim(),
          )
      : []) || [];

  const advisor_email_string = sunetIds
    .filter((item: string) => !excludedEmailsDirectors.has(item))
    .map((item: string) => `${item}@stanford.edu`)
    .join(" and ");

  //let d2irector_email_string = sunetIds.find((id: string) =>
  //  excludedEmailsDirectors.has(id),
  //)
  //  ? `${sunetIds.find((id: string) =>
  //      excludedEmailsDirectors.has(id),
  //    )}@stanford.edu`
  //  : "";
  let director_email_string = (mappingProgramDirector as any)[
    programProgramAcronym[userdataPersonal?.program as any]
  ];

  return (
    <div className="flex flex-col w-full min-h-screen">
      <HeaderStanford />
      <div className="cc grow w-full flex flex-col md:flex-row rs-py-2 overflow-x-hidden">
        <div className="mx-auto w-full">
          {userData.username ? (
            <article id="text__paragraphs" className="rs-mb-5">
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2">
                  <p className="text-4xl">Hello {userData.displayName},</p>
                  <p className="text-4xl">
                    This page will show you the status of your program proposal.
                    If any of your information is incorrect, please contact the
                    GSE Program Officer.
                  </p>
                  <p className="text-4xl mt-20">
                    If you need to{" "}
                    <a
                      href="https://ed.stanford.edu/students/forms/masters"
                      target="_blank"
                      rel="nofollower"
                    >
                      submit a course petition for your program
                    </a>
                    , please visit the Master's Forms page.
                  </p>
                </div>

                <div className="w-full md:w-1/2 flex bg-blue-200 md:ml-10 p-20">
                  <div className="w-full">
                    <h2 className="text-4xl font-bold mb-10">
                      Your Information
                    </h2>
                    <div>
                      <ul className="text-4xl">
                        <li>Program: {userdataPersonal?.program}</li>
                        <li>Director: {director_email_string}</li>
                        {!programDifferentiatorNoAdvisor(
                          programProgramAcronym[
                            userdataPersonal?.program as any
                          ],
                        ) && <li>Advisor: {advisor_email_string}</li>}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="text-white">
                  User Details: {userdataPersonal?.uid},{" "}
                  {userdataPersonal?.displayName},{" "}
                  {userdataPersonal?.eduPersonPrincipalName},{" "}
                  {userdataPersonal?.suAffiliation}, {userdataPersonal?.program},{" "}
                  {userdataPersonal?.program
                  ? programProgramAcronym[userdataPersonal?.program]
                  : ""}
                  </div> */}

              <>
                {pageLoading ? (
                  <SpinnerOverlay loading={pageLoading} />
                ) : (
                  <>
                    <h2 className="text-4xl mt-30">
                      Current Standing{" "}
                      {userdataPersonal?.program
                        ? `for ${getProgramVanityName(
                            programProgramAcronym[userdataPersonal?.program],
                          )} Program`
                        : ""}
                    </h2>

                    <CallToAction
                      smartsheetResponse={smartsheetResponse}
                      userdataPersonal={userdataPersonal}
                      //openModalAttachment={openModalAttachment}
                    />
                    {smartsheetResponse.length > 0 && (
                      <>
                        {/* <div className="mt-10 mb-6">
                          Your previous Program Proposals have been submitted. A
                          summary of your proposal submissions is shown below.
                          </div> */}
                        <SmartsheetPresentStatus
                          smartsheetResponse={smartsheetResponse}
                          userdataPersonal={userdataPersonal}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            </article>
          ) : (
            <div>
              <p className="text-4xl">
                To view the status of your proposal, please log in below.
              </p>
              <p>
                <button
                  onClick={() => handleSignIn()}
                  className="button"
                  type="button"
                >
                  Stanford Login
                </button>
              </p>
            </div>
          )}
        </div>
      </div>
      {/* <AttachmentModal
          student_email={`${userData!.username}@${baseEmailDomain}`}
          isOpen={isModalOpenAttachment}
          onClose={closeModalAttachment}
          setFiles={setFiles}
          setUploading={setUploading}
          files={files}
          uploading={uploading}
          smartsheetResponse={smartsheetResponse}
          setAttachmentProcessing={setAttachmentProcessing}
          setShouldPageRefreshContent={setShouldPageRefreshContent}
          /> */}

      {/* {attachmentProcessing ? (
          <SpinnerOverlay loading={attachmentProcessing} />
          ) : (
          <div></div>
          )} */}
      <FooterStanford />
    </div>
  );
};
export default Status;
