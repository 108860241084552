import { Field } from "formik";

const Attestation = () => {
  return (
    <div className="mt-10 flex items-center">
      <span className="mr-10">
        <Field
          name="attestation"
          type="text"
          placeholder="Initial"
          className="input w-24"
          style={{ width: "1in" }}
        />
      </span>
      <span>
        I attest, with my initials written here (e.g. Jane Stanford -&gt; JS),
        that the above plan satisfies all components of my program requirements
        to the best of my knowledge.
      </span>
    </div>
  );
};

export default Attestation;
