import { useRef, useEffect } from "react";
//import { useState } from "react";
//import { Field, useField } from "formik";
import { Field, useField } from "formik";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
//import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  determineBeyondExploreCourses,
  fetchCourseData,
  handleKeyDownCourseNumber,
  handleTextChangeCourseNumber,
  removeRow,
  handleCourseTitleUpdateManuallyObject,
} from "../extras/auxiliary_functions";

import {
  default215text,
  default180text,
  default190text,
} from "../extras/constant_values.tsx";

//import { FormError } from "../extras/errors";
//import { FormErrorsYearQuarterRow } from "../extras/interfaces";

const fieldHasError = (
  submitCount: number,
  errors: any,
  rowIndex: number,
  courseIndex: number,
  fieldName: string,
): boolean => {
  let errorClass = false;
  if (
    submitCount > 0 &&
    errors.rows &&
    errors.rows[rowIndex] &&
    errors.rows[rowIndex].courses &&
    errors.rows[rowIndex]?.courses?.[courseIndex] &&
    errors.rows[rowIndex]?.courses?.[courseIndex][fieldName]
  ) {
    errorClass = true;
  }
  return errorClass;
};

export const RowInputStandard: React.FC<{
  rowIndex: number;
  name: string;
  row: any;
}> = ({ rowIndex, name, row }) => (
  <Field
    type="text"
    name={`rows[${rowIndex}].${name}`}
    className="input"
    value={row[name]}
    disabled
  />
);

export const RowInputCourseNumber: React.FC<{
  courseIndex: number;
  errors: any;
  initialValues: any;
  rowIndex: number;
  setFieldValue: any;
  setProgramRequirement: any;
  submitCount: number;
  values: any;
  constantValues: any;
  currentQuarterIndex: any;
  courseAccessLastQuarterYearIndex: any;
}> = ({
  courseIndex,
  errors,
  initialValues,
  rowIndex,
  setFieldValue,
  setProgramRequirement,
  submitCount,
  values,
  constantValues,
  currentQuarterIndex,
  courseAccessLastQuarterYearIndex,
}) => {
  //const [year] = useField(`rows[${rowIndex}].year`);
  const beyondExploreCourses = determineBeyondExploreCourses(
    currentQuarterIndex,
    courseAccessLastQuarterYearIndex,
  );

  let errorClass = fieldHasError(
    submitCount,
    errors,
    rowIndex,
    courseIndex,
    "courseNumber",
  );

  // allow a student to manually type in their coursework, because explorecourses doesn't provide any courses
  if (beyondExploreCourses) {
    return (
      <>
        <div className="relative w-full">
          <Field
            type="text"
            className={`input pr-6 w-full ${errorClass ? "error" : ""}`}
            id={`rows[${rowIndex}].courses[${courseIndex}].courseNumber`}
            name={`rows[${rowIndex}].courses[${courseIndex}].courseNumber`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChangeCourseNumber(
                e,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
              )
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              handleKeyDownCourseNumber(
                e,
                values,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
                initialValues,
                beyondExploreCourses,
                constantValues,
              )
            }
            onBlur={() => {
              fetchCourseData(
                values,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
                initialValues,
                beyondExploreCourses,
                constantValues,
              );
            }}
          />
          {/* https://github.com/tailwindlabs/heroicons/issues/967 /> */}
          {/* adjusting stroke width /> */}
          <MagnifyingGlassIcon
            className="absolute right-4 top-1/2 transform -translate-y-1/2 h-14 w-14 text-slate-500 stroke-[3px]"
            title="Loading information from ExploreCourses"
            onClick={() =>
              fetchCourseData(
                values,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
                initialValues,
                beyondExploreCourses,
                constantValues,
              )
            }
            onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) =>
              handleKeyDownCourseNumber(
                e,
                values,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
                initialValues,
                beyondExploreCourses,
                constantValues,
              )
            }
            tabIndex={0} // Make the icon focusable
          />
        </div>
        {/* <FormError
          errors={errors as FormErrorsYearQuarterRow}
          rowIndex={rowIndex}
          courseIndex={courseIndex}
          fieldName="courseNumber"
          submitCount={submitCount}
          /> */}
      </>
    );
  } else {
    return (
      <>
        <div className="relative w-full">
          <Field
            type="text"
            className={`input pr-6 w-full ${errorClass ? "error" : ""}`}
            id={`rows[${rowIndex}].courses[${courseIndex}].courseNumber`}
            name={`rows[${rowIndex}].courses[${courseIndex}].courseNumber`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChangeCourseNumber(
                e,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
              )
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              handleKeyDownCourseNumber(
                e,
                values,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
                initialValues,
                beyondExploreCourses,
                constantValues,
              )
            }
            onBlur={() => {
              fetchCourseData(
                values,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
                initialValues,
                beyondExploreCourses,
                constantValues,
              );
            }}
          />
          {/* https://github.com/tailwindlabs/heroicons/issues/967 /> */}
          {/* adjusting stroke width /> */}
          <MagnifyingGlassIcon
            className="absolute right-4 top-1/2 transform -translate-y-1/2 h-14 w-14 text-slate-500 stroke-[3px]"
            title="Loading information from ExploreCourses"
            onClick={() =>
              fetchCourseData(
                values,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
                initialValues,
                beyondExploreCourses,
                constantValues,
              )
            }
            onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) =>
              handleKeyDownCourseNumber(
                e,
                values,
                rowIndex,
                courseIndex,
                setFieldValue,
                setProgramRequirement,
                initialValues,
                beyondExploreCourses,
                constantValues,
              )
            }
            tabIndex={0} // Make the icon focusable
          />
        </div>
        {/* <FormError
              errors={errors as FormErrorsYearQuarterRow}
              rowIndex={rowIndex}
              courseIndex={courseIndex}
              fieldName="courseNumber"
              submitCount={submitCount}
              /> */}
      </>
    );
  }
};

export const RowInputCourseTitle: React.FC<{
  courseIndex: number;
  errors: any;
  rowIndex: number;
  submitCount: number;
}> = ({ courseIndex, errors, rowIndex, submitCount }) => {
  //const [courseNumber, meta] = useField(

  const [courseNumber] = useField(
    `rows[${rowIndex}].courses[${courseIndex}].courseNumber`,
  );
  const [courseTitle] = useField(
    `rows[${rowIndex}].courses[${courseIndex}].courseTitle`,
  );
  const courseNumberValue = courseNumber.value;
  const courseTitleValue = courseTitle.value;

  //const { setFieldValue } = useFormikContext();
  //const inputRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const allowedCourseNumbers: any = {
      EDUC215: default215text,
      EDUC180: default180text,
      EDUC190: default190text,
    };

    if (
      courseNumberValue in allowedCourseNumbers &&
      courseTitleValue === allowedCourseNumbers[courseNumberValue]
    ) {
      setTimeout(() => {
        inputRef?.current?.focus();
        const code = courseNumberValue.replace(/^EDUC/, "");
        const objectyo = handleCourseTitleUpdateManuallyObject(code);
        const toastWarning = objectyo.toastWarning;
        toast.warning(toastWarning);
      }, 1000);
    }
  }, [courseNumberValue]);

  const fieldDisabled = !["EDUC215", "EDUC180", "EDUC190"].includes(
    courseNumberValue,
  );

  let errorClass = fieldHasError(
    submitCount,
    errors,
    rowIndex,
    courseIndex,
    "courseTitle",
  );

  return (
    <>
      <Field
        type="text"
        placeholder={
          fieldDisabled
            ? ""
            : "Please manually type in title (Coursework beyond the academic year range of ExploreCourses)"
        }
        name={`rows[${rowIndex}].courses[${courseIndex}].courseTitle`}
        className={`input border-white ${fieldDisabled ? "" : "ml-6 mr-10"} ${
          errorClass ? "error" : ""
        }`}
        disabled={fieldDisabled}
        onFocus={() => {
          console.log(" in the on focus courseTitleValue");
          console.log(courseTitleValue);
          //if (courseTitleValue === default215text) {
          //  setFieldValue(
          //    `rows[${rowIndex}].courses[${courseIndex}].courseTitle`,
          //    default215text,
          //  );
          //}
        }}
        innerRef={inputRef}
      />
      {/* <FormError
        errors={errors as FormErrorsYearQuarterRow}
        rowIndex={rowIndex}
        courseIndex={courseIndex}
        fieldName="courseTitle"
        submitCount={submitCount}
        /> */}
    </>
  );
};

export const RowInputCheckIfCrossListed: React.FC<{
  courseIndex: number;
  rowIndex: number;
}> = ({ courseIndex, rowIndex }) => {
  return (
    <>
      <Field
        type="checkbox"
        name={`rows[${rowIndex}].courses[${courseIndex}].crossListed`}
        className="transform scale-150"
      />
    </>
  );
};

export const RowInputGradingOption: React.FC<{
  courseIndex: number;
  errors: any;
  rowIndex: number;
  submitCount: number;
  values: any;
}> = ({ courseIndex, errors, rowIndex, submitCount, values }) => {
  let errorClass = fieldHasError(
    submitCount,
    errors,
    rowIndex,
    courseIndex,
    "gradingOption",
  );
  return (
    <>
      <Field
        as="select"
        className={`select ${errorClass ? "error" : ""}`}
        id={`rows[${rowIndex}].courses[${courseIndex}].gradingOption`}
        name={`rows[${rowIndex}].courses[${courseIndex}].gradingOption`}
        value={values.rows[rowIndex].courses[courseIndex].gradingOption}
        onClick={() => {
          console.log(values.rows[rowIndex].courses[courseIndex].gradingOption);
        }}
      >
        <>
          <option value="" disabled>
            Select Grading Option
          </option>
          {values.rows[rowIndex].courses[
            courseIndex
          ]?.availableGradingOptions?.map((option: any) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </>
      </Field>
      {/* <FormError
        errors={errors as FormErrorsYearQuarterRow}
        rowIndex={rowIndex}
        courseIndex={courseIndex}
        fieldName="gradingOption"
        submitCount={submitCount}
        /> */}
    </>
  );
};

export const RowInputProgramRequirement: React.FC<{
  courseIndex: number;
  errors: any;
  programRequirement: any;
  rowIndex: number;
  submitCount: number;
  values: any;
}> = ({
  courseIndex,
  errors,
  programRequirement,
  rowIndex,
  submitCount,
  values,
}) => {
  let errorClass = fieldHasError(
    submitCount,
    errors,
    rowIndex,
    courseIndex,
    "programRequirement",
  );

  return (
    <>
      <Field
        as="select"
        className={`select ${errorClass ? "error" : ""}`}
        id={`rows[${rowIndex}].courses[${courseIndex}].programRequirement`}
        name={`rows[${rowIndex}].courses[${courseIndex}].programRequirement`}
        value={values.rows[rowIndex].courses[courseIndex].programRequirement}
      >
        {programRequirement.length > 0 ? (
          <>
            <option value="" disabled>
              Select Requirement
            </option>
            {programRequirement.map((option: any) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </>
        ) : (
          <option value=""></option>
        )}
      </Field>
      {/* <FormError
        errors={errors as FormErrorsYearQuarterRow}
        rowIndex={rowIndex}
        courseIndex={courseIndex}
        fieldName="programRequirement"
        submitCount={submitCount}
        /> */}
    </>
  );
};

export const RowInputSingularUnits: React.FC<{
  course: any;
  courseIndex: number;
  errors: any;
  rowIndex: number;
  submitCount: number;
  values: any;
  currentQuarterIndex: any;
  courseAccessLastQuarterYearIndex: any;
}> = ({
  course,
  courseIndex,
  errors,
  rowIndex,
  submitCount,
  values,
  currentQuarterIndex,
  courseAccessLastQuarterYearIndex,
}) => {
  //const [year] = useField(`rows[${rowIndex}].year`);
  const unitsMax = values.rows[rowIndex].courses[courseIndex].unitsMax;
  const unitsMin = values.rows[rowIndex].courses[courseIndex].unitsMin;
  //const unitsMinDefault = 1;
  //const unitsMaxDefault = 5;

  const beyondExploreCourses = determineBeyondExploreCourses(
    currentQuarterIndex,
    courseAccessLastQuarterYearIndex,
  );

  let unitsRangeMessage = "";
  //if (beyondExploreCourses && course.courseUnits > 0) {
  //  // input singularUnits populated
  //  unitsRangeMessage = `Units:${unitsMinDefault}-${unitsMaxDefault}`;
  if (beyondExploreCourses && course.courseUnits === 0) {
    // input singularUnits not yet populated
    unitsRangeMessage = "";
  } else if (!course.courseTitle) {
    unitsRangeMessage = "";
  } else if (course.courseTitle.length === 0) {
    // if a explorecourses lookup is invalid
    unitsRangeMessage = "";
  } else if (typeof unitsMin !== "undefined" && unitsMin === unitsMax) {
    unitsRangeMessage = `Units:${unitsMin}`;
  } else if (unitsMin && unitsMax) {
    unitsRangeMessage = `Units:${unitsMin}-${unitsMax}`;
  } else {
    unitsRangeMessage = "";
  }
  let errorClass = fieldHasError(
    submitCount,
    errors,
    rowIndex,
    courseIndex,
    "programRequirement",
  );

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Field
          as="select"
          className={`input singularUnits ${errorClass ? "error" : ""}`}
          id={`rows[${rowIndex}].courses[${courseIndex}].courseUnits`}
          name={`rows[${rowIndex}].courses[${courseIndex}].courseUnits`}
          value={course.courseUnits || ""}
          disabled={!unitsMin || !unitsMax} // Disable the dropdown if values are not available
        >
          {unitsMin !== undefined && unitsMax !== undefined ? (
            [...Array(parseInt(unitsMax, 10) - parseInt(unitsMin, 10) + 1)].map(
              (_, i) => {
                const value = parseInt(unitsMin, 10) + i;
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              },
            )
          ) : (
            <option value="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
          )}
        </Field>
        <div className="ml-4 text-gray-400" style={{ fontSize: "1.6rem" }}>
          {unitsRangeMessage}
        </div>
      </div>

      {/* <FormError
        errors={errors as FormErrorsYearQuarterRow}
        rowIndex={rowIndex}
        courseIndex={courseIndex}
        fieldName="courseUnits"
        submitCount={submitCount}
        /> */}
    </>
  );
};

export const RowInputRemoveButton: React.FC<{
  courseIndex: number;
  rowIndex: number;
  setFieldValue: any;
  setInitialValuesLog: any;
  values: any;
}> = ({
  courseIndex,
  rowIndex,
  setFieldValue,
  setInitialValuesLog,
  values,
}) => {
  return (
    <>
      <button
        className="remove my-4 ml-6 text-stone-500"
        type="button"
        onClick={() =>
          removeRow(
            rowIndex,
            courseIndex,
            values,
            setFieldValue,
            setInitialValuesLog,
          )
        }
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </button>
    </>
  );
};
