import { ThreeDots } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const SpinnerOverlay: React.FC<{ loading: boolean }> = ({ loading }) => (
  <div
    className={`fixed inset-0 z-50 ${
      loading ? "" : "hidden"
    } overflow-auto bg-gray-800 bg-opacity-75`}
  >
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="bg-white p-12 mx-auto rounded shadow-lg mt-20 w-96 h-96 flex flex-col items-center justify-center">
        <div className="text-4xl text-gray-600 text-center mb-4 font-medium">
          Processing
        </div>
        <ThreeDots
          visible={true}
          height="90"
          width="90"
          color="grey"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
        <div className="text-2xl text-gray-500 text-center mt-4 font-light">
          Please do not refresh or close your browser window while your proposal
          submission is being processed.
          <br />
          <span className="">
            If this message does not disappear after 30 seconds, please contact
            Caroline Stasulat:{" "}
            <span>
              <a
                href="mailto:stasulat@stnford.edu"
                className="text-gray-500 hover:text-gray-700"
              >
                stasulat@stanford.edu
              </a>{" "}
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default SpinnerOverlay;
