interface UnitLimits {
  min: number;
  max: number;
}

interface SeasonalUnitLimits {
  Autumn: UnitLimits;
  Winter: UnitLimits;
  Spring: UnitLimits;
  Summer?: UnitLimits;
}

interface IceUnitLimits {
  a: SeasonalUnitLimits;
  b: SeasonalUnitLimits;
}

export interface QuarterlyUnitLimits {
  joint_ma_mba: { cumulativeUnits: number };
  designed: { cumulativeUnits: number };
  pols: { cumulativeUnits: number } & SeasonalUnitLimits;
  //cte: { cumulativeUnits: number } & SeasonalUnitLimits;
  ice: { cumulativeUnits: number } & IceUnitLimits;
  iepa: { cumulativeUnits: number } & IceUnitLimits;
  ldt: { cumulativeUnits: number } & SeasonalUnitLimits & {
      Summer: UnitLimits;
    };
  hcp: { cumulativeUnits: number } & SeasonalUnitLimits & {
      Summer: UnitLimits;
    };
  mpp: { cumulativeUnits: number } & SeasonalUnitLimits;
  eds: { cumulativeUnits: number } & SeasonalUnitLimits & {
      Summer: UnitLimits;
    };
}

export const quarterlyUnitLimits: QuarterlyUnitLimits = {
  //joint_ma_mba: { cumulativeUnits: 35 },
  joint_ma_mba: { cumulativeUnits: 45 },
  designed: { cumulativeUnits: 45 },
  pols: {
    cumulativeUnits: 45,
    Autumn: { min: 11, max: 18 },
    Winter: { min: 11, max: 18 },
    Spring: { min: 8, max: 18 },
  },
  //cte: {
  //  cumulativeUnits: 45,
  //| Autumn: { min: 11, max: 18 },
  //  Winter: { min: 11, max: 18 },
  //  Spring: { min: 11, max: 18 },
  //},
  ice: {
    cumulativeUnits: 48,
    a: {
      Autumn: { min: 11, max: 18 },
      Winter: { min: 11, max: 18 },
      Spring: { min: 11, max: 18 },
      Summer: { min: 3, max: 3 },
    },
    b: {
      Autumn: { min: 11, max: 18 },
      Winter: { min: 11, max: 18 },
      Spring: { min: 8, max: 10 },
      Summer: { min: 6, max: 6 },
    },
  },
  iepa: {
    cumulativeUnits: 48,
    a: {
      Autumn: { min: 11, max: 18 },
      Winter: { min: 11, max: 18 },
      Spring: { min: 11, max: 18 },
      Summer: { min: 3, max: 3 },
    },
    b: {
      Autumn: { min: 11, max: 18 },
      Winter: { min: 11, max: 18 },
      Spring: { min: 8, max: 10 },
      Summer: { min: 6, max: 6 },
    },
  },
  ldt: {
    cumulativeUnits: 45,
    Autumn: { min: 3, max: 18 },
    Winter: { min: 3, max: 18 },
    Spring: { min: 3, max: 18 },
    Summer: { min: 2, max: 10 },
  },
  hcp: {
    cumulativeUnits: 45,
    Autumn: { min: 3, max: 18 },
    Winter: { min: 3, max: 18 },
    Spring: { min: 3, max: 18 },
    Summer: { min: 0, max: 6 },
  },
  mpp: {
    cumulativeUnits: 95,
    Autumn: { min: 8, max: 18 },
    Winter: { min: 8, max: 18 },
    Spring: { min: 8, max: 18 },
    //Summer: { min: 0, max: 18 },
  },
  eds: {
    cumulativeUnits: 51,
    Autumn: { min: 5, max: 18 },
    Winter: { min: 3, max: 18 },
    Spring: { min: 8, max: 18 },
    // overrides: For EDS students with an override, let's set the minimum at 3 and maximum of 18 during Spring Quarter of year 2.
    Summer: { min: 0, max: 10 },
  },
};

/*
   const quarterlyUnitLimits = {
   joint_ma_mba: { cumulativeUnits: 35 },
   designed: { cumulativeUnits: 45 },
   pols: {
   cumulativeUnits: 45,
   WIN: { min: 11, max: 18 },
   SPR: { min: 8, max: 18 },
   AUT: { min: 11, max: 18 },
   },
   cte: {
   cumulativeUnits: 45,
   WIN: { min: 11, max: 18 },
   SPR: { min: 11, max: 18 },
   AUT: { min: 11, max: 18 },
   },
   ice: {
   cumulativeUnits: 48,
   WIN: { a: { min: 11, max: 18 }, b: { min: 11, max: 18 } },
   SPR: { a: { min: 11, max: 18 }, b: { min: 8, max: 10 } },
   AUT: { a: { min: 11, max: 18 }, b: { min: 11, max: 18 } },
   SUM: { a: { min: 3, max: 3 }, b: { min: 6, max: 6 } },
   },
   ldt: {
   cumulativeUnits: 45,
   WIN: { min: 3, max: 18 },
   SPR: { min: 3, max: 18 },
   AUT: { min: 3, max: 18 },
   SUM: { min: 2, max: 10 },
   },
   hcp: {
   cumulativeUnits: 45,
   WIN: { min: 3, max: 18 },
   SPR: { min: 3, max: 18 },
   AUT: { min: 3, max: 18 },
   SUM: { min: 0, max: 6 },
   },
   mpp: {
   cumulativeUnits: 95,
   WIN: { min: 8, max: 18 },
   SPR: { min: 8, max: 18 },
   AUT: { min: 8, max: 18 },
   },
   eds: {
   cumulativeUnits: 51,
   WIN: { min: 3, max: 18 },
   SPR: { min: 8, max: 18 },
   AUT: { min: 5, max: 18 },
   SUM: { min: 0, max: 10 },
   },
   };

 */

/*
   interface QuarterlyUnitLimits {
   cumulativeUnits: number;
   WIN?: { min: number; max: number };
   SPR?: { min: number; max: number };
   AUT?: { min: number; max: number };
   SUM?: { min: number; max: number };
   }
   // Rest of the code remains unchanged

   interface QuarterlyUnitLimitsContainer {
   [key: string]: QuarterlyUnitLimits;
   }

   export const quarterlyUnitLimits: QuarterlyUnitLimitsContainer = {
   joint_ma_mba: { cumulativeUnits: 35 },
   designed: { cumulativeUnits: 45 },
   pols: {
   cumulativeUnits: 45,
   WIN: { min: 11, max: 18 },
   SPR: { min: 8, max: 18 }, // Covid 19 exception, POLS spring minimum 11 units lowered
   AUT: { min: 11, max: 18 },
   },
   cte: {
   cumulativeUnits: 45,
   WIN: { min: 11, max: 18 },
   SPR: { min: 11, max: 18 },
   AUT: { min: 11, max: 18 },
   },
   ice: {
   cumulativeUnits: 48,
   WIN: { a: { min: 11, max: 18 }, b: { min: 11, max: 18 } },
   SPR: { a: { min: 11, max: 18 }, b: { min: 8, max: 10 } },
   AUT: { a: { min: 11, max: 18 }, b: { min: 11, max: 18 } },
   SUM: { a: { min: 3, max: 3 }, b: { min: 6, max: 6 } },
   },
   ldt: {
   cumulativeUnits: 45,
   WIN: { min: 3, max: 18 },
   SPR: { min: 3, max: 18 },
   AUT: { min: 3, max: 18 },
   SUM: { min: 2, max: 10 },
   },
   hcp: {
   cumulativeUnits: 45,
   WIN: { min: 3, max: 18 },
   SPR: { min: 3, max: 18 },
   AUT: { min: 3, max: 18 },
   SUM: { min: 0, max: 6 },
   },
   mpp: {
   cumulativeUnits: 95,
   WIN: { min: 8, max: 18 },
   SPR: { min: 8, max: 18 },
   AUT: { min: 8, max: 18 },
   },
   eds: {
   cumulativeUnits: 51,
   WIN: { min: 3, max: 18 },
   SPR: { min: 8, max: 18 },
   AUT: { min: 5, max: 18 },
   SUM: { min: 0, max: 10 },
   },
   };

 */
// MA/MBA: no quarterly minimum/maximums
// MA/JD: no quarterly minimum/maximums
// Individually Designed: no quarterly minimum/maximums

/*
   quarterlyUnitLimits = {};
   quarterlyUnitLimits.joint_ma_mba = {};
   quarterlyUnitLimits.joint_ma_mba.cumulativeUnits = 35;

   quarterlyUnitLimits.designed = {};
   quarterlyUnitLimits.designed.cumulativeUnits = 45;

   quarterlyUnitLimits.pols = {};
   quarterlyUnitLimits.pols.cumulativeUnits = 45;
   quarterlyUnitLimits.pols.WIN = {};
   quarterlyUnitLimits.pols.WIN.min = 11;
   quarterlyUnitLimits.pols.WIN.max = 18;
   quarterlyUnitLimits.pols.SPR = {};

   // Covid 19 exception, POLS spring minimum 11 units lowered
   // stasulate email subject: 2020-04-17: Final program proposal
   // $scope.quarterlyUnitLimits.pols.SPR.min = 11;
   quarterlyUnitLimits.pols.SPR.min = 8;

   quarterlyUnitLimits.pols.SPR.max = 18;
   quarterlyUnitLimits.pols.AUT = {};
   quarterlyUnitLimits.pols.AUT.min = 11;
   quarterlyUnitLimits.pols.AUT.max = 18;

   quarterlyUnitLimits.cte = {};
   quarterlyUnitLimits.cte.cumulativeUnits = 45;
   quarterlyUnitLimits.cte.WIN = {};
   quarterlyUnitLimits.cte.WIN.min = 11;
   quarterlyUnitLimits.cte.WIN.max = 18;
   quarterlyUnitLimits.cte.SPR = {};
   quarterlyUnitLimits.cte.SPR.min = 11;
   quarterlyUnitLimits.cte.SPR.max = 18;
   quarterlyUnitLimits.cte.AUT = {};
   quarterlyUnitLimits.cte.AUT.min = 11;
   quarterlyUnitLimits.cte.AUT.max = 18;

   quarterlyUnitLimits.ice = {}; // ICE/IEPA
   quarterlyUnitLimits.ice.cumulativeUnits = 48;
   quarterlyUnitLimits.ice.WIN = {};
   quarterlyUnitLimits.ice.WIN.a = {};
   quarterlyUnitLimits.ice.WIN.b = {};
   quarterlyUnitLimits.ice.WIN.a.min = 11;
   quarterlyUnitLimits.ice.WIN.a.max = 18;
   quarterlyUnitLimits.ice.WIN.b.min = 11;
   quarterlyUnitLimits.ice.WIN.b.max = 18;
   quarterlyUnitLimits.ice.SPR = {};
   quarterlyUnitLimits.ice.SPR.a = {};
   quarterlyUnitLimits.ice.SPR.b = {};
   quarterlyUnitLimits.ice.SPR.a.min = 11;
   quarterlyUnitLimits.ice.SPR.a.max = 18;
   quarterlyUnitLimits.ice.SPR.b.min = 8;
   quarterlyUnitLimits.ice.SPR.b.max = 10;
   quarterlyUnitLimits.ice.AUT = {};
   quarterlyUnitLimits.ice.AUT.a = {};
   quarterlyUnitLimits.ice.AUT.b = {};
   quarterlyUnitLimits.ice.AUT.a.min = 11;
   quarterlyUnitLimits.ice.AUT.a.max = 18;
   quarterlyUnitLimits.ice.AUT.b.min = 11;
   quarterlyUnitLimits.ice.AUT.b.max = 18;
   quarterlyUnitLimits.ice.SUM = {};
   quarterlyUnitLimits.ice.SUM.a = {};
   quarterlyUnitLimits.ice.SUM.b = {};
   quarterlyUnitLimits.ice.SUM.a.min = 3;
   quarterlyUnitLimits.ice.SUM.a.max = 3;
   quarterlyUnitLimits.ice.SUM.b.min = 6;
   quarterlyUnitLimits.ice.SUM.b.max = 6;

   // $scope.quarterlyUnitLimits.ldt = {};
   // $scope.quarterlyUnitLimits.ldt.cumulativeUnits = 45;
   // $scope.quarterlyUnitLimits.ldt.WIN = {};
   // $scope.quarterlyUnitLimits.ldt.WIN.min = 8;
   // $scope.quarterlyUnitLimits.ldt.WIN.max = 18;
   // $scope.quarterlyUnitLimits.ldt.SPR = {};
   // $scope.quarterlyUnitLimits.ldt.SPR.min = 8;
   // $scope.quarterlyUnitLimits.ldt.SPR.max = 18;
   // $scope.quarterlyUnitLimits.ldt.AUT = {};
   // $scope.quarterlyUnitLimits.ldt.AUT.min = 8;
   // $scope.quarterlyUnitLimits.ldt.AUT.max = 18;
   // $scope.quarterlyUnitLimits.ldt.SUM = {};
   // $scope.quarterlyUnitLimits.ldt.SUM.min = 6;
   // $scope.quarterlyUnitLimits.ldt.SUM.max = 6;

   quarterlyUnitLimits.ldt = {};
   quarterlyUnitLimits.ldt.cumulativeUnits = 45;
   quarterlyUnitLimits.ldt.WIN = {};
   quarterlyUnitLimits.ldt.WIN.min = 3;
   quarterlyUnitLimits.ldt.WIN.max = 18;
   quarterlyUnitLimits.ldt.SPR = {};
   quarterlyUnitLimits.ldt.SPR.min = 3;
   quarterlyUnitLimits.ldt.SPR.max = 18;
   quarterlyUnitLimits.ldt.AUT = {};
   quarterlyUnitLimits.ldt.AUT.min = 3;
   quarterlyUnitLimits.ldt.AUT.max = 18;
   quarterlyUnitLimits.ldt.SUM = {};
   quarterlyUnitLimits.ldt.SUM.min = 2;
   quarterlyUnitLimits.ldt.SUM.max = 10;

   quarterlyUnitLimits.hcp = {};
   quarterlyUnitLimits.hcp.cumulativeUnits = 45;
   quarterlyUnitLimits.hcp.WIN = {};
   quarterlyUnitLimits.hcp.WIN.min = 3;
   quarterlyUnitLimits.hcp.WIN.max = 18;
   quarterlyUnitLimits.hcp.SPR = {};
   quarterlyUnitLimits.hcp.SPR.min = 3;
   quarterlyUnitLimits.hcp.SPR.max = 18;
   quarterlyUnitLimits.hcp.AUT = {};
   quarterlyUnitLimits.hcp.AUT.min = 3;
   quarterlyUnitLimits.hcp.AUT.max = 18;
   quarterlyUnitLimits.hcp.SUM = {};
   quarterlyUnitLimits.hcp.SUM.min = 0;
   quarterlyUnitLimits.hcp.SUM.max = 6;

   quarterlyUnitLimits.mpp = {};
   quarterlyUnitLimits.mpp.cumulativeUnits = 95;
   quarterlyUnitLimits.mpp.WIN = {};
   quarterlyUnitLimits.mpp.WIN.min = 8;
   quarterlyUnitLimits.mpp.WIN.max = 18;
   quarterlyUnitLimits.mpp.SPR = {};
   quarterlyUnitLimits.mpp.SPR.min = 8;
   quarterlyUnitLimits.mpp.SPR.max = 18;
   quarterlyUnitLimits.mpp.AUT = {};
   quarterlyUnitLimits.mpp.AUT.min = 8;
   quarterlyUnitLimits.mpp.AUT.max = 18;

   quarterlyUnitLimits.eds = {};
   quarterlyUnitLimits.eds.cumulativeUnits = 51;
   quarterlyUnitLimits.eds.WIN = {};
   quarterlyUnitLimits.eds.WIN.min = 3;
   quarterlyUnitLimits.eds.WIN.max = 18;
   quarterlyUnitLimits.eds.SPR = {};
   quarterlyUnitLimits.eds.SPR.min = 8;
   quarterlyUnitLimits.eds.SPR.max = 18;
   quarterlyUnitLimits.eds.AUT = {};
   quarterlyUnitLimits.eds.AUT.min = 5;
   quarterlyUnitLimits.eds.AUT.max = 18;
   quarterlyUnitLimits.eds.SUM = {};
   quarterlyUnitLimits.eds.SUM.min = 0;
   quarterlyUnitLimits.eds.SUM.max = 10;
 */
