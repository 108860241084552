const SiteGridExample = () => {
  return (
    <>
      <div className="cc flex flex-col md:flex-row rs-py-6">
        <div className="mx-auto w-full">
          <article id="css-grid" className="rs-mb-5">
            <header>
              <h3 className="type-5 text-lagunita-light">CSS Grid</h3>
            </header>
            <div className="overflow-hidden">
              <div className="grid grid-cols-3 grid-gap">
                <div className="bg-sky-light flex justify-center items-center rs-p-3 type-3 font-bold">
                  1
                </div>
                <div className="bg-sky-light flex justify-center items-center rs-p-3 type-3 font-bold">
                  2
                </div>
                <div className="bg-sky-light flex justify-center items-center rs-p-3 type-3 font-bold">
                  3
                </div>
                <div className="col-span-2 bg-sky-dark text-white flex justify-center items-center rs-p-3 type-3 font-bold">
                  4
                </div>
                <div className="bg-sky-light flex justify-center items-center rs-p-3 type-3 font-bold">
                  5
                </div>
                <div className="bg-sky-light flex justify-center items-center rs-p-3 type-3 font-bold">
                  6
                </div>
                <div className="col-span-2 bg-sky-dark text-white flex justify-center items-center rs-p-3 type-3 font-bold">
                  7
                </div>
                <div className="col-span-3 bg-sky text-white flex justify-center items-center rs-p-3 type-3 font-bold">
                  8
                </div>
              </div>
            </div>
          </article>
          <article id="flex-grid" className="rs-mb-5">
            <header>
              <h3 className="type-5 text-lagunita-light">Flex Grid</h3>
            </header>
            <div className="flex flex-col md:flex-row gap-xs lg:gap-lg xl:gap-xl 2xl:gap-2xl text-white rs-mb-2">
              <div className="w-full md:w-1/2 bg-palo-verde-light rs-p-2">
                <h3>6 of 12 columns</h3>
                <p className="card-paragraph">
                  This column stacks vertically for XS-SM breakpoints and spans
                  6 of 12 columns for MD and up; responsive grid gaps.
                </p>
              </div>
              <div className="w-full md:w-1/2 bg-palo-verde-dark rs-p-2">
                <h3>6 of 12 columns</h3>
                <p className="card-paragraph">
                  This column stacks vertically for XS-SM breakpoints and spans
                  6 of 12 columns for MD and up; responsive grid gaps.
                </p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row grid-gap rs-mb-2 text-white">
              <div className="w-full sm:w-1/2 lg:w-3/4 bg-spirited rs-p-2">
                <h3>8 of 12 columns</h3>
                <p className="card-paragraph">
                  This column stacks vertically for XS breakpoint; spans 6 of 12
                  columns for SM-MD; spans 8 of 12 columns for LG and up;
                  responsive column gaps using .grid-gap className.
                </p>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/4 bg-spirited-dark rs-p-2">
                <h3>4 of 12 columns</h3>
                <p className="card-paragraph">
                  This column stacks vertically for XS breakpoint; spans 6 of 12
                  columns for SM-MD; spans 4 of 12 columns for LG and up;
                  responsive column gaps using .grid-gap className.
                </p>
              </div>
            </div>
          </article>
          <article id="aspect-ratio" className="rs-mb-5">
            <header>
              <h3 className="type-5 text-lagunita-light">
                Aspect Ratio Containers
              </h3>
            </header>
            <p>
              Note: The aspect ratio classNamees have to be added to a wrapper
              container around your element. To use a width decorator className,
              you'll need to add a parent container that wraps around your
              aspect ratio container. Below are a few commonly used ones.
            </p>
            <div className="w-full md:w-1/3 rs-mb-2">
              <div className="aspect-w-1 aspect-h-1 bg-illuminating-dark text-black">
                <div className="rs-p-4 grid justify-center content-center">
                  <h3 className="mb-0">Square</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 rs-mb-2">
              <div className="aspect-w-4 aspect-h-3 bg-plum-light text-white">
                <div className="rs-p-4 grid justify-center content-center">
                  <h3 className="mb-0">4 x 3 Box</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 rs-mb-2">
              <div className="aspect-w-16 aspect-h-9 bg-bay-dark text-white">
                <div className="rs-p-4 grid justify-center content-center">
                  <h3 className="mb-0">16 x 9 Box</h3>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 rs-mb-2">
              <div className="aspect-w-2 aspect-h-1 bg-poppy-dark text-white">
                <div className="rs-p-4 grid justify-center content-center">
                  <h3 className="mb-0">2 x 1 Box</h3>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default SiteGridExample;
